import {
    PAYMENTVOUCHER_VIEW
} from "../../constant/ActionType";

export default function PaymentVoucherViewiReducer(state = {
    paymentvoucherallget: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case PAYMENTVOUCHER_VIEW:
            return {
                ...state,
                paymentvoucherallget: action.data.data.logs,
                statusCode: action.status
            };
        default:

    }
    return state;
}