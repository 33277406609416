import { Button } from "antd";
import { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
} from "@mui/material";
import { UploadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { dispatch } from "store/index";
import { fetchTaxesComp } from "store/action/index";
import ThemeInput from "components/inputs/ThemeInput";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
// import style from '..components/TaxOption.css';

const TaxOption = ({
  options,
  setOptions,
  taxValue,
  settaxValue,
  taxes,
  sub_total_value,
}) => {
  const [numFields, setNumFields] = useState(1);

  const increaseFields = () => {
    // setNumFields(numFields + 1);
    setOptions([...options, { tax: "" }]);
  };

  const decreaseFields = (indexValue) => {
    if (options.length > 1) {
      // setNumFields(numFields - 1);
      setOptions(options.filter((_, index) => index !== indexValue));
    }
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index].tax = value;

    const selectedTax = taxes.find((tax) => tax.id === value);

    // Set additional properties
    updatedOptions[index].tax_value = selectedTax
      ? selectedTax.tax_value
      : null;
    updatedOptions[index].tax_type = selectedTax ? selectedTax.tax_type : null;

    setOptions(updatedOptions);

    const taxVal = updatedOptions
      .map((option) => option.tax_value)
      .filter(Boolean);
    const taxType = updatedOptions
      .map((option) => option.tax_type)
      .filter(Boolean);

    const adjustedTaxValues = [];

    for (let i = 0; i < taxType.length; i++) {
      let adjustedValue = taxVal[i];

      // if (taxType[i] === '1') {
      //   // Divide taxValue by 100
      //   adjustedValue /= 100;
      // }

      adjustedTaxValues.push(adjustedValue);
    }
    settaxValue(adjustedTaxValues);


  };


  return (
    <>
      <div
        className="row "
        style={{ justifyContent: "space-between", padding: 0 }}
      >
        <div>
          {options.map((_, index) => (
            <div
              className="my-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                padding: 0,
              }}
            >
              <Grid item xs={4.2} sx={{ display: "flex", gap: "8px" }}>
                <Stack spacing={1}>
                  <InputLabel
                    htmlFor={`option-${index}`}
                    style={{ fontWeight: "bold", marginBottom: "0px" }}
                  >
                    Tax {index + 1}
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      value={options[index]?.tax}
                      onChange={(e) =>
                        handleOptionChange(index, e.target.value)
                      }
                      id={`option-${index}`}
                      name="roleid"
                      input={<OutlinedInput />}
                      style={{ minWidth: "200px", marginBottom: "15px" }}
                    >
                      <MenuItem value="">
                        <em>Select Tax</em>
                      </MenuItem>
                      {taxes &&
                        taxes.length !== 0 &&
                        taxes.map((v) => {
                          return (
                            <MenuItem value={v.tax_value}>
                              {v.tax_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Stack>

                {/* <div  className= "col-md-6 ">   */}
                <ThemeInput
                  name="Tax Amount"
                  disabled={true}
                  placeholder="Total"
                  value={
                    options[index]?.tax_value
                      ? options[index]?.tax_type === "1"
                        ? (sub_total_value / 100) *
                        Number(options[index]?.tax_value)
                        : options[index]?.tax_value
                      : ""
                  }
                />
                {/* </div>  */}
              </Grid>

              <div className="mt-3">
                <Button
                  size="large"
                  key="add"
                  className="mx-2"
                  style={{ backgroundColor: "lightblue" }}
                  onClick={increaseFields}
                >
                  <PlusOutlined
                    size={25}
                    color={"#fff"}
                    style={{ position: "relative", bottom: "3px" }}
                  />
                </Button>
                <Button
                  size="large"
                  key="remove"
                  className="ml-2"
                  type="primary"
                  style={{ backgroundColor: "red" }}
                  onClick={() => decreaseFields(index)}
                >
                  <DeleteOutlined
                    size={25}
                    color={"#fff"}
                    style={{ position: "relative", bottom: "3px" }}
                  />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    taxes: state?.taxesdata?.taxes?.data,
  };
};

export default connect(mapStateToProps)(TaxOption);
