import MainCard from "components/MainCard";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin, Table, Space, Input, Modal, Tooltip } from "antd";
import { dispatch } from "store/index";
import { fetchViewQuotation, fetchTaxesComp } from "store/action/index";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FilePdfOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { color } from "../../../node_modules/@mui/system/index";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

// ==============================|| Create / Edit Company - SICPA ||============================== //

const ViewSingleQuotation = ({ viewQuotionData, taxes }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pdfUrl, setpdfUrl] = useState("");
  const [conditionOverAll, setCondtionOvelAlling] = useState([]);
  const [OverAllTax, setOverAllTax] = useState([
    { tax_id: "", tax_value: "", tax_type: "" },
  ]);
  const [discount_type, setdiscountType] = useState("");
  const [discount, setdiscount] = useState();
  const [tableData, setTableData] = useState([
    {
      columnsdata: [
        {
          s_no: "1",
          description: "",
          qty: 0,
          unit: "",
          unit_price: 0,
          total_price: 0,
          title_name: ""
        },
      ],
      sub_total: "",
      taxes: [{ tax_id: "", tax_value: "", tax_type: "" }],
      total: "",
      table_title: "",
      discount: "",
      discount_type: "",
      sub_title: "",
    },
  ]);
  const [pdfUrl2, setpdfUrl2] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewMainQuotation, setViewMainQuotation] = useState({});
  const quotation_type = {
    1: "Recieving",
    2: "ADHOC",
    3: "Quotation Vice",
    no: "--empty--",
  };

  const getColumns = (tableIndex) => [
    {
      title: "S.no",
      dataIndex: "s_no",
      width: 100,
      key: "s_no",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description*",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      render: (text, record, index) => (
        <Input
          value={record.description}
        />
      ),
    },
    {
      title: "Quantity*",
      dataIndex: "qty",
      key: "qty",
      render: (text, record, index) => (
        <Input
          type="number"
          value={record.qty}
        />
      ),
    },
    {
      title: "Unit Price*",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (text, record, index) => (
        <div>
          <Input
            type="number"
            value={isNaN(record.unit_price) ? 0 : record.unit_price}

          />
        </div>
      ),
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (text, record, index) => (
        <Input
          type="number"
          value={isNaN(record.total_price) ? 0 : record.total_price}
          disabled
        />
      ),
    }
  ];

  const getSummmaryColumns = [
    {
      title: "Table Title",
      dataIndex: "table_title",
      key: "table_title",
    },
    {
      title: "Tax Price",
      dataIndex: "taxes",
      key: "taxes",
      render: (text, record) => {
        return record?.total > 0 ? record?.total - record?.sub_total || 0 : ""; // Ensure it returns 0 if there are no applicable taxes
      },
    },
    {
      title: "Discount Price",
      dataIndex: "discount",
      key: "discount",
      render: (text, record) => {
        const discountValue = Number(record?.discount) || 0; // Convert discount to a number
        const discountType = String(record?.discount_type); // Convert discount_type to string
        const subTotal = Array.isArray(record?.columnsdata) && record?.columnsdata?.length > 0
          ? record.columnsdata.reduce((sum, item) => {
            // Convert total_price to number, removing quotes if present
            const price = Number(item.total_price?.replace?.(/"/g, '') || 0);
            return sum + price;
          }, 0)
          : 0;

        if (subTotal > 0 && discountValue > 0) {
          if (discountType == "1") {
            const percentageDiscount = (subTotal * discountValue) / 100;
            return percentageDiscount.toFixed(2);
          } else if (discountType == "2") {
            return discountValue.toFixed(2); // Flat discount
          }
        }
        return ""; // Return empty if no valid discount
      },
    },
    {
      title: "Sub Total",
      dataIndex: "sub_total",
      key: "sub_total",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  useEffect(() => {
    if (viewQuotionData &&
      viewQuotionData?.main_quotation &&
      viewQuotionData?.main_quotation?.quo_Refs?.length !== 0) {
      // setpdfUrl(
      //   viewQuotionData.url + "/" + viewQuotionData?.main_quotation?.file_path
      // );
      // setpdfUrl2(
      //   viewQuotionData.url + "/" + viewQuotionData?.main_quotation?.file_path2
      // );
      setpdfUrl(viewQuotionData?.main_quotation?.file_path);
      setpdfUrl2(viewQuotionData?.main_quotation?.file_path2);
      setViewMainQuotation(viewQuotionData?.main_quotation);
      setdiscount(viewQuotionData?.main_quotation?.discount);
      setdiscountType(viewQuotionData?.main_quotation?.discount_type);
      setCondtionOvelAlling(
        viewQuotionData?.main_quotation?.overAllCondition == 1
          ? [1, 2]
          : viewQuotionData?.main_quotation?.overAllCondition == 2
            ? [2]
            : []
      );
      setOverAllTax(JSON.parse(viewQuotionData?.main_quotation?.tax_id))

      const updatedData = viewQuotionData?.main_quotation?.quo_Refs?.map(
        (item) => {
          const qt_items_no_title = item.qt_items.filter((v) => !v?.title_name);
          const qt_items_with_title = item.qt_items.filter(
            (v) => v?.title_name
          );

          const qt_item_combine = qt_items_no_title.map((v) => {
            const qt_match = qt_items_with_title.find(
              (wt) => wt?.s_no == v?.s_no
            );
            if (qt_match) {
              return { ...v, title_name: qt_match?.title_name };
            } else {
              return v;
            }
          });

          return {
            columnsdata: qt_item_combine,
            defaultExpand: qt_items_with_title.map((v) => v?.s_no),
            total: item.table_grand_total,
            table_title: item.table_heading,
            taxes: item?.table_tax_id && Array.isArray(JSON.parse(item?.table_tax_id)) && JSON.parse(item?.table_tax_id)?.length > 0
              ? JSON.parse(item?.table_tax_id)?.map((tax) => {
                return {
                  ...tax,
                  id: tax.tax_id, // Rename tax_id to id
                  tax_value: tax.tax_value,
                  tax_name: tax.tax_name,
                  tax_type: tax.tax_type,
                };
              })
              : [],
            sub_title: item.table_sub_heading,
            sub_total: item.table_sub_total,
            discount: item.table_discount,
            discount_type: item.table_discount_type,
          };
        }
      );

      setTableData(updatedData);
    }
  }, [viewQuotionData]);

  useEffect(() => {
    dispatch(fetchTaxesComp());

    const payload = { id: id };
    dispatch(
      fetchViewQuotation({
        payload,
        setLoading: setLoading,
      })
    );
  }, []);

  const handleViewPDF = (type = null) => {
    window.open(type == "without" ? pdfUrl2 : pdfUrl);
  };
  //   const handleViewPDF = (type = null) => {
  //     const url = type === "without" ? pdfUrl2 : pdfUrl;
  //     window.open(url);
  // };


  // const handleApproved = (id, params) => {
  //   navigate(`/invoice/create-invoice/${id}`, { state: params });
  // };

  const handleApproved = (id, params) => {
    navigate(`/invoice/create-invoice/${id}`, { state: { ...params, source: "quotation" } });
  };


  const getTaxColumns = (tableIndex) => [
    {
      title: "Tax*",
      dataIndex: "taxes",
      key: "taxes",
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor={`option-${index}`}
              style={{ fontWeight: "bold", marginBottom: "0px" }}
            >
              Tax {index + 1}
            </InputLabel>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={record.tax_id} // Use tax_id as the value for Select
                disabled={true}
                id={`option-${index}`}
                name="roleid"
                input={<OutlinedInput />}
                style={{ minWidth: "200px", marginBottom: "15px" }}
              >
                {taxes &&
                  taxes.length !== 0 &&
                  taxes.map((v) => (
                    <MenuItem key={v.id} value={v.id}>
                      {v.tax_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>

          <ThemeInput
            type="text"
            name="Amount*"
            value={record.tax_value} // Use the appropriate value from the record
            disabled={true}
          />
        </div>
      ),
    }
  ];

  const OverAllApplyTaxColumns = [
    {
      title: "Tax*",
      dataIndex: "taxes",
      key: "taxes",
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor={`option-${index}`}
              style={{ fontWeight: "bold", marginBottom: "0px" }}
            >
              Tax {index + 1}
            </InputLabel>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={record.id} // Use the id as the value for Select
                id={`option-${index}`}
                name="roleid"
                disabled={true}
                input={<OutlinedInput />}
                style={{ minWidth: "200px", marginBottom: "15px" }}
              >
                {taxes &&
                  taxes.length !== 0 &&
                  taxes.map((v) => (
                    <MenuItem key={v.id} value={v.id}>
                      {v.tax_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>

          <ThemeInput
            type="text"
            name="Amount*"
            value={record.tax_value}
            disabled={true}
          />
        </div>
      ),
    }
  ];

  const renderExpandedRow = (record, tableIndex) => {
    return (
      <Input
        value={record.title_name}
        style={{
          marginBottom: "5px",
        }}
        placeholder="Enter title"
      />
    );
  };

  const handleRowExpand = (expand, record, tableIndex) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].columnsdata) &&
      !expand
    ) {
      const updatedFields = [...updatedTableData[tableIndex].columnsdata];
      if (updatedFields[+record?.s_no - 1] !== undefined) {
        const updatedFieldsvalue = updatedFields.map((v, i) => {
          if (i == +record?.s_no - 1) {
            return {
              ...v,
              // title_name: "",
            };
          } else {
            return v;
          }
        });
        updatedTableData[tableIndex].columnsdata = updatedFieldsvalue;
        setTableData(updatedTableData);
      }
    }
  };

  const calculateTotalTaxes = (grandTotal) => {
    // First check if OverAllTax is an array and has items
    if (!Array.isArray(OverAllTax) || !OverAllTax.length) {
      return 0;
    }

    return OverAllTax.reduce((acc, tax) => {
      // Check if the tax object is valid
      if (!tax || typeof tax !== "object") {
        return acc; // Skip invalid entries
      }

      const taxValue = parseFloat(tax.tax_value) || 0;
      if (tax.tax_type === "1") {
        return acc + (grandTotal * taxValue) / 100;
      } else if (tax.tax_type === "2") {
        return acc + taxValue;
      }
      return acc;
    }, 0);
  };

  const calculateGrandTotal = () => {
    let grandTotal = tableData.reduce((acc, table) => {
      return acc + (parseFloat(table.total) || 0);
    }, 0);
    if (discount_type == "1") {
      grandTotal -= (grandTotal * discount) / 100;
    } else if (discount_type == "2") {
      grandTotal -= discount;
    }
    const totalTaxes = calculateTotalTaxes(grandTotal);
    grandTotal += totalTaxes;

    return grandTotal;
  };

  const GetGrandTotal = () => {
    // Calculate subtotal from tableData
    const grandTotal = tableData.reduce((acc, table) => {
      return acc + (parseFloat(table.total) || 0);
    }, 0);

    const discountValue = Number(discount) || 0; // Convert discount to a number
    const discountType = String(discount_type); // Convert discount_type to string

    // Apply discount if applicable
    if (grandTotal > 0 && discountValue > 0) {
      if (discountType === "1") {
        // Percentage discount
        const percentageDiscount = (grandTotal * discountValue) / 100;
        return (percentageDiscount).toFixed(2);
      } else if (discountType === "2") {
        // Flat discount
        return (discountValue).toFixed(2);
      }
    }

    return grandTotal.toFixed(2); // Return the grand total if no discount applies
  };

  return (
    <MainCard>
      <Spin spinning={loading}>
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <div className="row">
              <div className=" d-flex ">
                <div className="my-2">
                  <Tooltip title="View PDF With WaterMark">
                    <Button
                      onClick={() => handleViewPDF()}
                      disableElevation
                      variant="contained"
                      style={{ height: "50px", color: "#fff" }}
                    >
                      PDF with watermark
                      <FilePdfOutlined
                        className="px-3"
                        style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        size="medium"
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip title="View PDF Without WaterMark">
                    <Button
                      className="mx-2"
                      onClick={() => handleViewPDF("without")}
                      disableElevation
                      variant="contained"
                      style={{ height: "50px", color: "#fff" }}
                    >
                      PDF without watermark
                      <FilePdfOutlined
                        className="px-3"
                        style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        size="medium"
                      />
                    </Button>
                  </Tooltip>
                </div>
                <div className="my-2 mx-2">
                  {viewMainQuotation?.status === 1 && (
                    <Tooltip title="Approved">
                      {/* <Link to={`/invoice/create-invoice/${id}`}> */}
                      <CheckCircleOutlined
                        disableElevation
                        className="px-3"
                        size="medium"
                        type="submit"
                        variant="contained"
                        style={{
                          height: "50px",
                          fontSize: "45px",
                          color: "#ED1D24",
                        }}
                        // onClick={() => handleApproved()}
                        onClick={() => handleApproved(id, viewMainQuotation)}

                      />
                      {/* </Link> */}
                    </Tooltip>
                  )}
                  {viewMainQuotation?.status === 2 && (
                    <span className="btn btn-primary">Approved</span>
                  )}
                  {viewMainQuotation?.status === 3 && (
                    <span className="btn btn-info">Draft</span>
                  )}
                  {viewMainQuotation?.status === 5 && (
                    <span className="btn btn-danger">Expired</span>
                  )}
                </div>
              </div>
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Qoutation Number
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.quote_num ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Company
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.company_name ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Customer
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.customer_name ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Quotation Type
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {quotation_type[
                        viewMainQuotation?.quotation_type ?? "no"
                      ] ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Date
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.date ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Quotation Serial Number
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.number ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Payment term
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.payment_term ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Currency
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.currency ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Expiry Date
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.valid_till ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>
            </div>

            {tableData?.map((v, index) => {
              return (
                <div key={index}>
                  <div className="row my-2">
                    <div className="col-md-6 my-2">
                      <ThemeInput
                        name="Table Title"
                        value={v.table_title} // Accessing from map data

                        placeholder="Title"
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <ThemeInput
                        name="Sub Title"
                        value={v.sub_title} // Accessing from map data

                        placeholder="Title"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <Table
                      className="rounded"
                      pagination={false}
                      columns={getColumns(index)}
                      dataSource={v?.columnsdata} // Make sure to use the correct dataSource
                      scroll={{ x: 1300 }}
                      expandable={{
                        expandedRowRender: (record) => renderExpandedRow(record, index),
                        onExpand: (expanded, record) => handleRowExpand(expanded, record, index),
                      }}
                      defaultExpandedRowKeys={v?.defaultExpand ?? ["1"]}
                      rowKey={"s_no"}
                    />
                  </div>

                  <div className="row d-flex justify-content-between mt-3">
                    <div className="col-md-3 my-2">
                      <ThemeInput
                        name="Sub Total"
                        disabled={true}
                        value={v.sub_total} // Accessing from map data
                        placeholder="Total"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <Stack spacing={1}>
                        <InputLabel
                          htmlFor="AssignRole"
                          style={{ fontSize: "13px" }}
                        >
                          Table Discount Type*
                        </InputLabel>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            displayEmpty
                            input={<OutlinedInput />}
                            disabled={conditionOverAll.includes(1)}
                            style={{ height: "52px" }}
                            value={v.discount_type}

                          >
                            <MenuItem value="">
                              <em>Select Discount Type</em>
                            </MenuItem>
                            <MenuItem value="1">Percentage %</MenuItem>
                            <MenuItem value="2">Fix</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                    </div>
                    <div className="col-md-3 my-2">
                      <ThemeInput
                        name="Table Discount*"
                        value={v.discount}
                        placeholder="Discount"
                        disabled={conditionOverAll.includes(1)}

                      />
                    </div>
                  </div>

                  <div className="row justify-content-end">
                    <div className="col-md-6 my-2">
                      <Table
                        className="rounded"
                        pagination={false}
                        columns={getTaxColumns(index)}
                        dataSource={v?.taxes} // Make sure to use the correct dataSource
                        scroll={{ x: 300 }}
                        style={{ maxWidth: "700px", margin: "0 auto" }} // Set max width and center alignment
                      />
                    </div>
                    <div className="col-md-6 my-2 mt-auto">
                      <ThemeInput
                        name="Total"
                        disabled={true} // Disabled input, cannot be edited by the user
                        value={v?.total}
                        placeholder="Total"
                      />
                    </div>
                  </div>

                </div>
              );
            })}

            {tableData?.length <= 1 ? (
              <></>
            ) : (
              <div className="row">
                <Stack spacing={1}>
                  <InputLabel
                    style={{ fontWeight: "bold", marginBottom: "0px" }}
                  >
                    Condition
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={conditionOverAll.includes(1)}
                            name="overallDiscount"
                            color="primary"
                          />
                        }
                        label="Overall Discount"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={conditionOverAll.includes(2)}
                            disabled={conditionOverAll.includes(1) && conditionOverAll.includes(2)}
                            name="overallTax"
                            color="primary"
                          />
                        }
                        label="Overall Tax"
                      />
                    </FormGroup>
                  </FormControl>
                </Stack>
              </div>
            )}

            <div className="row">
              {conditionOverAll.includes(1) && (
                <>
                  {discount_type !== "" ? <div className="col-md-4 my-2">
                    <Stack spacing={1}>
                      <InputLabel
                        htmlFor="AssignRole"
                        style={{ fontSize: "13px" }}
                      >
                        Discount Type*
                      </InputLabel>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          displayEmpty
                          input={<OutlinedInput />}
                          style={{ height: "52px" }}
                          value={discount_type}
                          disabled={true}
                        >
                          <MenuItem value="">
                            <em>Select Discount Type</em>
                          </MenuItem>
                          <MenuItem value="1">Percentage %</MenuItem>
                          <MenuItem value="2">Fix</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </div> : <></>}
                  {discount > 0 ? <div className="col-md-4 my-2">
                    <ThemeInput
                      type="number"
                      name="Discount*"
                      value={discount !== 0 ? discount : ""}
                      placeholder="Discount"
                    />
                  </div> : <></>}
                </>
              )}

              {conditionOverAll.includes(2) && (
                <div className="col-md-12 my-2">
                  <Table
                    className="rounded"
                    pagination={false}
                    columns={OverAllApplyTaxColumns}
                    dataSource={OverAllTax} // Use the correct dataSource
                    scroll={{ x: 300 }}
                    style={{ maxWidth: "700px", margin: "0 auto" }} // Set max width and center alignment
                  />
                </div>
              )}
            </div>

            <div
              className="row"
              style={{ display: "flex", justifyContent: "left" }}
            >
              <Table
                className="rounded"
                pagination={false}
                columns={getSummmaryColumns}
                dataSource={tableData} // Make sure to use the correct dataSource
                scroll={{ x: 300 }}
                style={{ maxWidth: "700px", margin: "auto 0" }} // Set max width and center alignment
              />
            </div>

            <h6
              style={{
                textAlign: "left",
                fontSize: "14px",
                marginTop: "20px",
              }}
            >
              Grand Total: {calculateGrandTotal()}
              {/* {(() => {
                  let grandTotal = tableData?.reduce((acc, table) => {
                    return acc + (parseFloat(table.total) || 0);
                  }, 0);

                  return grandTotal;
                })()} */}
            </h6>
            {discount > 0 ? (
              <h6 style={{ textAlign: "left" }}>Discount : {GetGrandTotal()}</h6>
            ) : (
              <></>
            )}
            <h6
              style={{
                textAlign: "left",
                fontSize: "14px",
                marginTop: "20px",
              }}
            >
              Tax:{" "}
              {conditionOverAll.includes(2) ? (
                <>
                  {/* <h6>
                     {calculateTotalTaxes(
                       calculateGrandTotal() -
                         calculateTotalTaxes(calculateGrandTotal())
                     )}
                   </h6> */}
                  <h6>
                    {calculateTotalTaxes(
                      tableData.reduce(
                        (acc, table) => acc + (parseFloat(table.total) || 0),
                        0
                      )
                    )}
                  </h6>
                </>

              ) : (
                tableData.reduce((acc, table) => {
                  return table?.total > 0
                    ? Number(acc) + (table?.total - table?.sub_total || 0)
                    : ""; // Accumulate the difference for each table
                }, 0)
              )}
            </h6>
            <h6
              style={{
                textAlign: "left",
                fontSize: "14px",
                marginTop: "20px",
              }}
            >
              Sub Total:{" "}
              {(() => {
                let total = tableData.reduce((acc, table) => {
                  return acc + (parseFloat(table.sub_total) || 0);
                }, 0);

                // Apply discount on the total of all tables combined
                if (discount_type == "1") {
                  total = total - (total * discount) / 100;
                } else if (discount_type == "2") {
                  total = total - discount;
                }

                return total;
              })()}
            </h6>

          </Grid>
        </Grid>
      </Spin>
    </MainCard>
  );
};

const mapStateToProps = (state) => {
  return {
    viewQuotionData: state?.quotationView?.viewQuotation?.data,
    taxes: state?.taxesdata?.taxes?.data,

  };
};
export default connect(mapStateToProps)(ViewSingleQuotation);
