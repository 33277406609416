import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

const NavigationBreadcrumb = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const getNavigationPath = () => {
        const path = location.pathname;
        // Define parent-child relationships
        const navigationMap = {
            '/quotation/all-quotations': { name: 'Quotation', parent: null },
            '/quotation/create-quotation': { name: 'Create Quotation', parent: '/quotation/all-quotations' },
            '/invoice/all-invoices': { name: 'Invoice', parent: null },
            '/invoice/create-invoice/create-invoice': { name: 'Create Invoice', parent: '/invoice/all-invoices' },
            '/domain-parameter/payment-voucher': { name: 'Voucher', parent: null },
            '/domain-parameter/create-payment-voucher': { name: 'Create Payment Voucher', parent: '/domain-parameter/payment-voucher' },
            '/domain-parameter/expense-voucher': { name: 'Create Expense Voucher', parent: '/domain-parameter/payment-voucher' },
            '/delivery-note/all-challan': { name: 'Delivery Note', parent: null },
            '/delivery-note/create-challan': { name: 'Create Delivery Note', parent: '/delivery-note/all-challan' },
            '/hrm-modules/employee-info': { name: 'Employee Info', parent: null },
            '/hrm-modules/emp-info-create': { name: 'Add Employee', parent: '/hrm-modules/employee-info' },
            '/hrm-modules/payroll-management': { name: 'Payroll Management', parent: null },
            '/hrm-modules/payroll-payslip': { name: 'PaySlip', parent: '/hrm-modules/payroll-management' },
            '/leave-management/all-leaves': { name: 'All Leaves', parent: null },
            '/leave-management/leave-management-create': { name: 'Create Leave', parent: '/leave-management/all-leaves' },
            '/reports/all-reports': { name: 'Reports', parent: null },
            '/reports/ledger': { name: 'Ledger', parent: '/reports/all-reports' },
            '/userManagment/all-users': { name: 'Users', parent: null },
            '/user-management/register-user': { name: 'Create User', parent: '/userManagment/all-users' },
            '/domain-parameter/all-templates': { name: 'All Templates', parent: null },
            '/domain-parameter/create-template': { name: 'Create Template', parent: '/domain-parameter/all-templates' },
            '/recuring/all-recuring-email': { name: 'All Recuring Email', parent: null },
            '/recuring/create-recuring-email': { name: 'Create Recuring Email', parent: '/recuring/all-recuring-email' },
            '/domain-parameter/all-taxes': { name: 'All Taxes', parent: null },
            '/domain-parameter/create-tax': { name: 'Add Tax', parent: '/domain-parameter/all-taxes' },
            '/domain-parameter/designation': { name: 'All Designation', parent: null },
            '/domain-parameter/branch': { name: 'All Branch', parent: null },
            '/domain-parameter/leave-type': { name: 'All Leave Type', parent: null },
            '/domain-parameter/payroll-allowance': { name: 'All Allowance', parent: null },
            '/domain-parameter/payroll-deduction': { name: 'All Deduction', parent: null },
            '/domain-parameter/currency': { name: 'All Currency', parent: null },
            '/domain-parameter/projects-management': { name: 'All Projects', parent: null },
            '/domain-parameter/projects-management-create': { name: 'Add Project', parent: '/domain-parameter/projects-management' },
            '/domain-parameter/chart-of-account': { name: 'All Chart Of Account', parent: null },
            '/domain-parameter/create-account': { name: 'Add Chart Of Account', parent: '/domain-parameter/chart-of-account' },
            '/domain-parameter/terms-and-conditions': { name: 'All Terms And Condition', parent: null },
            '/domain-parameter/create-terms-and-condition': { name: 'Add Terms and Condition', parent: '/domain-parameter/terms-and-conditions' },
            '/domain-parameter/all-company': { name: 'Company', parent: null },
            '/domain-parameter/create-company': { name: 'Create Company', parent: '/domain-parameter/all-company' },
            '/domain-parameter/all-customer': { name: 'Customer', parent: null },
            '/domain-parameter/create-customer': { name: 'Create Customer', parent: '/domain-parameter/all-customer' }
        };

        const currentRoute = navigationMap[path];
        if (!currentRoute) return null;

        return {
            parent: currentRoute.parent,
            current: currentRoute.name
        };
    };

    const handleNavigate = (route) => {
        if (route) {
            navigate(route); // Navigate to the parent route
        }
    };

    const navigationPath = getNavigationPath();

    if (!navigationPath) return null;

    return (
        <div>
            <h1
                style={{
                    fontSize: "13px",
                    marginLeft: "11px",
                    marginBottom: "10px",
                    marginTop: "0px",
                    fontFamily: "poppins",
                    color: "rgb(133 134 135)",
                }}
            >
                {navigationPath.parent ? (
                    <>
                        <span
                            style={{ textDecoration: 'none', cursor: 'pointer' }} className="text-gray-500"
                            onClick={() => handleNavigate(navigationPath.parent)}
                        >
                            {navigationPath.parent.split('/').pop()} {/* Display the parent name */}
                        </span>
                        <span className="mx-2 text-gray-500">&gt;</span>
                        <span>{navigationPath.current}</span>
                    </>
                ) : (
                    navigationPath.current
                )}
            </h1>
        </div>
    );
};

export default NavigationBreadcrumb;
