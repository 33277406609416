import shop from "../shop/shop";
import * as types from "../constant/ActionType";

export const fssetchsogsinUser = (data) => ({
  type: types.FETCH_LOGIN_DATA,
  data,
});

export const storequAoser = (data) => ({
  type: types.STORE_QUOTATION,
  data,
});
export const updateQuotation = (data) => ({
  type: types.UPDATE_QUOTATION,
  data,
});
export const viewQuotation = (data) => ({
  type: types.VIEW_QUOTATION,
  data,
});

export const contantAllviewroless = (data) => ({
  type: types.VIEW_ROLES,
  data,
});
export const rolePermissions = (data) => ({
  type: types.ROLE_PERMISSIONS,
  data,
});
export const invoiceSmall = (data) => ({
  type: types.INVOICE_REGISTER,
  data,
});

export const childALLPermisssions = (data) => ({
  type: types.GETALL_PERMISSION,
  data,
});

export const mainInvoice = (data) => ({
  type: types.MAIN_INVOICE,
  data,
});
export const mainInvoiceUpdate = (data) => ({
  type: types.MAIN_INVOICE_UPDATE,
  data,
});

export const fetchchildchallan = (data) => ({
  type: types.CHALLAN_SEND,
  data,
});

export const fetchtemplatesmallss = (data) => ({
  type: types.TEMPLATE_GET,
  data,
});

export const NewfetchUpdatedata = (data) => ({
  type: types.UPDATE_COMPANY,
  data,
});

export const Childassigpermissions = (data) => ({
  type: types.ASSIGN_PERMISSIONS,
  data,
});

export const fetchEdittempplate = (data) => ({
  type: types.TEMPLATE_UPDATE,
  data,
});

export const updatechildmail = (data) => ({
  type: types.UPDATE_RECURING_EMAIL,
  data,
});

export const fetchdeletetempattyye = (data) => ({
  type: types.TEMPLATE_DELETE,
  data,
});

export const NewfetcheditCustomerr = (data) => ({
  type: types.UPDATE_CUSTOMER,
  data,
});

export const NewfetchDeletedata = (data) => ({
  type: types.DELETE_COMPANY,
  data,
});

export const fetchremoveAccountof = (data) => ({
  type: types.DELETE_ACCOUNT,
  data,
});

export const NewfetchDeletecustomerr = (data) => ({
  type: types.DELETE_CUSTOMER,
  data,
});
// ____________________________________________DASHBOARD___________________________________________________________________
export const reportsAllinvoice = (data) => ({
  type: types.INVOICE_REPORTS,
  data,
});

export const reportsAllquotations = (data) => ({
  type: types.QUOTATIONS_REPORTS,
  data,
});

export const reportsAllchallans = (data) => ({
  type: types.CHALLANS_REPORTS,
  data,
});
export const reportsGetTopRatedCustomers = (data) => ({
  type: types.TOP_RATED_CUSTOMERS,
  data,
});

// ____________________________________________DASHBOARD___________________________________________________________________

// _________________________________REPORT_________________________________________________________________

export const reportsAllledger = (data) => ({
  type: types.LEDGER_REPORTS,
  data,
});

export const exportinvoiceAll = (data) => ({
  type: types.INVOICE_ALL_EXPORT,
  data,
});

export const exportvoucherAll = (data) => ({
  type: types.VOUCHER_ALL_EXPORT,
  data,
});

export const exportquotationAll = (data) => ({
  type: types.QUOTATIONS_ALL_EXPORT,
  data,
});

export const exportchallanAll = (data) => ({
  type: types.CHALLAN_ALL_EXPORT,
  data,
});

export const Deletequotation = (data) => ({
  type: types.QUOTATIONS_ALL_DELETE,
  data,
});

export const Deleteinvoice = (data) => ({
  type: types.INVOICE_ALL_DELETE,
  data,
});

export const Deletechallan = (data) => ({
  type: types.CHALLAN_ALL_DELETE,
  data,
});

export const Deletevoucher = (data) => ({
  type: types.VOUCHER_ALL_DELETE,
  data,
});

export const leavemang = (data) => ({
  type: types.LEAVE_MANAGEMENT,
  data,
});

export const leavemangCr = (data) => ({
  type: types.LEAVE_MANAGEMENT_CREATE,
  data,
});

export const generateslryCr = (data) => ({
  type: types.GENERATE_SALARY_CREATE,
  data,
});

export const generateslryUpdate = (data) => ({
  type: types.GENERATE_SALARY_UPDATE,
  data,
});

export const Getleavemang = (data) => ({
  type: types.LEAVE_MANAGEMENT_GET,
  data,
});

export const mangCreate = (data) => ({
  type: types.CREATE_LEAVE_MANAGEMENT,
  data,
});

export const leavemangUpdte = (data) => ({
  type: types.LEAVE_MANAGEMENT_UPDATE,
  data,
});

export const leavemangDel = (data) => ({
  type: types.LEAVE_MANAGEMENT_DELETE,
  data,
});

export const AllEmployeeMang = (data) => ({
  type: types.ALL_EMPLOYEE_MANAGEMENT,
  data,
});


export const AllEmployeeMangfilters = (data) => ({
  type: types.ALL_EMPLOYEE_MANAGEMENT_FILTER,
  data,
});

export const childEmployeeExcel = (data) => ({
  type: types.ALL_EMPLOYEE_EXCEL,
  data,
});

export const LoanTypeget = (data) => ({
  type: types.ALL_LOAN_TYPE,
  data,
});

export const AllRostercrc = (data) => ({
  type: types.ROSTER_CREATE,
  data,
});

export const CrcEmployeeMang = (data) => ({
  type: types.CREATE_EMPLOYEE_MANAGEMENT,
  data,
});


export const EditEmployeeMang = (data) => ({
  type: types.UPDATE_EMPLOYEE_MANAGEMENT,
  data,
});

export const getPolicy = (data) => ({
  type: types.GET_COMPANY_POLICY,
  data,
});

export const createPolicy = (data) => ({
  type: types.CREATE_COMPANY_POLICY,
  data,
});

export const LeaveRequestApproved = (data) => ({
  type: types.LEAVES_REQUEST_APPROVED,
  data,
});

export const CancelLeaveRequest = (data) => ({
  type: types.LEAVES_REQUEST_CANCEL,
  data,
});

export const RejectLeaveRequest = (data) => ({
  type: types.LEAVES_REQUEST_REJECT,
  data,
});

export const getLoans = (data) => ({
  type: types.GET_ALL_LOANS,
  data,
});

export const getPayslip = (data) => ({
  type: types.GET_ALL_PAYSLIP,
  data,
});


export const getPayslipExport = (data) => ({
  type: types.EXPORT_ALL_PAYSLIP,
  data,
});

export const getSalaryPay = (data) => ({
  type: types.GET_ALL_PAYSALARY,
  data,
});
export const fetchfiltergetSalaryPay = (data) => ({
  type: types.GET_ALL_PAYSALARY_FETCH,
  data,
});



export const getAttendance = (data) => ({
  type: types.GET_ALL_ATTENDANCE,
  data,
});


export const getAttendanceexport = (data) => ({
  type: types.EXPORT_GET_ATTENDANCE,
  data,
});

export const crcAttendance = (data) => ({
  type: types.CREATE_ALL_ATTENDANCE,
  data,
});

export const extractAttendance = (data) => ({
  type: types.EXTRACT_ALL_ATTENDANCE,
  data,
});

export const getPayroll = (data) => ({
  type: types.GET_ALL_PAYROLL,
  data,
});

export const getDeduction = (data) => ({
  type: types.GET_ALL_DEDUCTION,
  data,
});

export const getAllowanceAmount = (data) => ({
  type: types.GET_ALL_PAYROLL_ALLOWANCE,
  data,
});

export const getCurrencyy = (data) => ({
  type: types.GET_ALL_CURRENCY,
  data,
});

export const createCurrencyy = (data) => ({
  type: types.CREATE_ALL_CURRENCY,
  data,
});

export const editCurrencyy = (data) => ({
  type: types.UPDATE_ALL_CURRENCY,
  data,
});

export const deleteCurrencyy = (data) => ({
  type: types.DELETE_ALL_CURRENCY,
  data,
});

export const createAllowanceAmount = (data) => ({
  type: types.CREATE_ALL_PAYROLL_ALLOWANCE,
  data,
});

export const updateAllowanceAmount = (data) => ({
  type: types.UPDATE_ALL_PAYROLL_ALLOWANCE,
  data,
});


export const deleteAllowanceAmount = (data) => ({
  type: types.DELETE_ALL_PAYROLL_ALLOWANCE,
  data,
});

export const getAllowanEmp = (data) => ({
  type: types.ALL_EMPLOYEE_ALLOWANCE,
  data,
});

export const editAllowanEmp = (data) => ({
  type: types.UPDATE_EMPLOYEE_ALLOWANCE,
  data,
});

export const editSatDeduction = (data) => ({
  type: types.UPDATE_SATURATION_DEDUCTION,
  data,
});

export const editotherpayMents = (data) => ({
  type: types.UPDATE_OTHER_PAYMENTS,
  data,
});

export const getAllowancePayroll = (data) => ({
  type: types.ALL_PAYROLL_ALLOWANCE,
  data,
});

export const createEmpsalary = (data) => ({
  type: types.CREATE_EMP_SALARY,
  data,
});

export const getEmpsalary = (data) => ({
  type: types.GET_EMPLOYEE_SALARY,
  data,
});

export const createLoans = (data) => ({
  type: types.CREATE_ALL_LOANS,
  data,
});

export const returnLoans = (data) => ({
  type: types.CREATE_RETURN_LOANS,
  data,
});

export const updateLoans = (data) => ({
  type: types.EDIT_ALL_LOANS,
  data,
});
// ___________________________________REPORTS________________________________________________________________________________

export const fetchTaxdelete = (data) => ({
  type: types.DELETE_TAXES,
  data,
});

export const fetchTaxupdate = (data) => ({
  type: types.UPDATE_TAXES,
  data,
});

export const fetchupdateAccountChart = (data) => ({
  type: types.EDIT_ACCOUNT,
  data,
});

export const fetchregistercompany = (data) => ({
  type: types.REGISTER_COMPANY,
  data,
});

export const fetchregistertax = (data) => ({
  type: types.REGISTER_TAXES,
  data,
});

export const accountregisterr = (data) => ({
  type: types.REGISTER_ACCOUNT,
  data,
});

export const fetchregistercustomere = (data) => ({
  type: types.CUSTOMER_REGISTER,
  data,
});

export const PostChangePassword = (data) => ({
  type: types.CHANGE_PASSWORD,
  data,
});

export const paymentsmallvouch = (data) => ({
  type: types.PAYMENT_VOUCHER,
  data,
});
export const expensesmallvouch = (data) => ({
  type: types.EXPENSE_VOUCHER,
  data,
});
export const update_paymentsmallvouch = (data) => ({
  type: types.PAYMENT_VOUCHER_UPDATE,
  data,
});

export const viewsmallPaymentsses = (data) => ({
  type: types.PAYMENTVOUCHER_VIEW,
  data,
});

export const fetchallanDetail = (data) => ({
  type: types.CHALLAN_DETAILS,
  data,
});

export const detchvocieDetail = (data) => ({
  type: types.INVOICE_DETAILS,
  data,
});

export const fetchuotationDetail = (data) => ({
  type: types.QUOTATION_DETAILS,
  data,
});

export const smallfetchtemdetai = (data) => ({
  type: types.NEWTEMAE_DETAILS,
  data,
});

export const fetchComapny = (data) => ({
  type: types.FETCH_COMPANY,
  data,
});

export const CustomerDataEdit = (data) => ({
  type: types.CUSTOMER_EDIT,
  data,
});

export const CustomerDataDelete = (data) => ({
  type: types.CUSTOMER_DELETE,
  data,
});

export const CustomerData = (data) => ({
  type: types.CUSTOMER_DATA,
  data,
});

export const fetchCurrencyData = (data) => ({
  type: types.FETCH_CURRENCY,
  data,
});

export const fetchDasboardData = (data) => ({
  type: types.FETCH_DASHBOARD,
  data,
});
export const PaymentVoucherDetailData = (data) => ({
  type: types.PAYMENT_VOUCHER_DATAILS,
  data,
});

export const fetchTaxess = (data) => ({
  type: types.FETCH_TAXES,
  data,
});

export const fetchNumberfield = (data) => ({
  type: types.NUMBER_FIELD,
  data,
});

export const specificroless = (data) => ({
  type: types.CREATE_ROLE,
  data,
});

export const fetchCustomerr = (data) => ({
  type: types.GET_CUSTOMER,
  data,
});

export const fetchquatIons = (data) => ({
  type: types.QUOTATION_ALLGET,
  data,
});

export const fetchUsersAll = (data) => ({
  type: types.ALLUSERS,
  data,
});

export const detailsUsersAll = (data) => ({
  type: types.ALLUSERSDETAILS,
  data,
});

export const deleteUsersAll = (data) => ({
  type: types.ALL_USER_DELETE,
  data,
});

export const createUsersAll = (data) => ({
  type: types.ALLCREATEUSERS,
  data,
});

export const fetchsmallInvoice = (data) => ({
  type: types.INVOICE_ALLVIEW,
  data,
});

// export const fetchmainDash = (data) => ({
//   type: types.MAIN_DASHBOARD,
//   data
// })

export const fetchsmallChallan = (data) => ({
  type: types.CHALLAN_ALLVIEW,
  data,
});

export const fetchAccountt = (data) => ({
  type: types.CHART_ACCOUNT,
  data,
});

export const fetcheditchallan = (data) => ({
  type: types.CHALLAN_UPDATE,
  data,
});

export const fetchinvoiceeidt = (data) => ({
  type: types.INVOICE_UPDATE,
  data,
});

export const childtermconiupdatte = (data) => ({
  type: types.TERMS_EDIT,
  data,
});

export const condtiotermdlete = (data) => ({
  type: types.TERMS_DELETE,
  data,
});

export const fetchchildsenregistr = (data) => ({
  type: types.TERMS_SENDFETCH,
  data,
});

export const temrscidntiondetail = (data) => ({
  type: types.TERMS_DETAILS,
  data,
});

export const termsconditionssalllll = (data) => ({
  type: types.TERMS_ALLGET,
  data,
});

export const termsconditionssactivee = (data) => ({
  type: types.TERMS_ALLACTIVE,
  data,
});

export const fetchchildtempregi = (data) => ({
  type: types.TEMPLATE_FETCH,
  data,
});

export const recuringChild = (data) => ({
  type: types.RECURING_ALL_DATA,
  data,
});

export const createRedChild = (data) => ({
  type: types.RECURING_FETCH,
  data,
});

export const typeCheckreports = (data) => ({
  type: types.ALL_GET_REPORTS,
  data,
});


export const getProjProfitandLossreports = (data) => ({
  type: types.ALL_PROJECT_PROFIT_AND_LOSS,
  data,
});

export const getCustomerEarningreports = (data) => ({
  type: types.ALL_CUSTOMER_EARNING_REPORTS,
  data,
});

export const getExpenseReportsSummary = (data) => ({
  type: types.ALL_EXPENSE_SUMMARY_REPORTS,
  data,
});

export const getOverallReportsPL = (data) => ({
  type: types.ALL_OVERALL_PROFIT_LOSS,
  data,
});


export const getCompanyReportsPL = (data) => ({
  type: types.ALL_COMPANY_PROFIT_LOSS,
  data,
});


export const unlockeddpff = (data) => ({
  type: types.ALL_PDF_UNLOCK,
  data,
});

export const increptionPass = (data) => ({
  type: types.INCREPTION_PASS_DATA,
  data,
});

export const childhanldebranch = (data) => ({
  type: types.CREATE_BRANCHES_BNS,
  data,
});

export const hanldechilddesignation = (data) => ({
  type: types.CREATE_DESIGNATION_CRS,
  data,
});

export const leavetypehandlechild = (data) => ({
  type: types.CREATE_LEAVE_TYPE_IS,
  data,
});

export const getchildhanldebranch = (data) => ({
  type: types.BRANCHES_VIEW_DATAS,
  data,
});



export const getchildhanldevendors = (data) => ({
  type: types.ALL_VIEW_VENDORS,
  data,
});


export const childhanldevendors = (data) => ({
  type: types.CREATE_VIEW_VENDORS,
  data,
});




export const hanldegetchilddesignation = (data) => ({
  type: types.DESIGNATIONSS_GET_DATA_VIEW,
  data,
});

// export const getleavesmanag = (data) => ({
//   type: types.GET_LEAVE_MANAGEMENT,
//   data,
// });

export const leavetypehandlegetchild = (data) => ({
  type: types.LEAVETYPE_GT_VIEW,
  data,
});

export const activechilddeactivebranche = (data) => ({
  type: types.ACTIVE_DEACTIVE_BRANCHE,
  data,
});

export const activechilddesignationsdeactive = (data) => ({
  type: types.ACTIVE_DEACTIVE_DSGNTS,
  data,
});

export const editchildbrandediting = (data) => ({
  type: types.BRANCH_EDIT_UPDATE,
  data,
});

export const childDispatcheditdesgn = (data) => ({
  type: types.DESIGNATIONS_EDIT_UPDATE,
  data,
});

export const leavtypechildeidt = (data) => ({
  type: types.LEAVE_TYPE_EDIT,
  data,
});

export const fetchchildleavtypeupdatestatsius = (data) => ({
  type: types.LEAVE_TYPE_STATUS_UPDATE,
  data,
});

export const deleteexpenseempallchild = (data) => ({
  type: types.DELETE_EMP_EXPENSE_SALARY,
  data,
});

export const createsalarychilds = (data) => ({
  type: types.CREATE_SALARY_FETCH,
  data,
});


export const crcAmountdeduction = (data) => ({
  type: types.CREATE_AMOUNT_DEDUCTION,
  data,
});

export const updateAmountdeduction = (data) => ({
  type: types.UPDATE_AMOUNT_DEDUCTION,
  data,
});

export const deleteAmountdeduction = (data) => ({
  type: types.DELETE_AMOUNT_DEDUCTION,
  data,
});



export const getprojectschilds = (data) => ({
  type: types.GET_ALL_PROJECTS,
  data,
});

export const createprojectschilds = (data) => ({
  type: types.CREATE_ALL_PROJECTS,
  data,
});


export const editprojectschilds = (data) => ({
  type: types.UPDATE_ALL_PROJECTS,
  data,
});

export const fetchStatusprojectschilds = (data) => ({
  type: types.UPDATE_STATUS_PROJECTS,
  data,
});



export const PostcsvUploadEmployee = (data) => ({
  type: types.UPLOAD_EMPLOYEE_CSV,
  data,
});

export const AllUploadCsvEmployees = (data) => (dispatch) => {
  shop.UploadCSVEmployee(data, (e) => {
    dispatch(PostcsvUploadEmployee(e, data));
  });
};



export const StatusUpdateFetchProjects = (data) => (dispatch) => {
  shop.UpdateStatusAllProjects(data, (e) => {
    dispatch(fetchStatusprojectschilds(e, data));
  });
};

export const EditFetchProjects = (data) => (dispatch) => {
  shop.UpdateAllProjects(data, (e) => {
    dispatch(editprojectschilds(e, data));
  });
};

export const CreateFetchProjects = (data) => (dispatch) => {
  shop.CreateAllProjects(data, (e) => {
    dispatch(createprojectschilds(e, data));
  });
};


export const GetFetchProjects = (data) => (dispatch) => {
  shop.GetAllProjects(data, (e) => {
    dispatch(getprojectschilds(e, data));
  });
};



export const fetchStatusemployeeschilds = (data) => ({
  type: types.UPDATE_STATUS_EMPLOYEE,
  data,
});

export const StatusUpdateEmployee = (data) => (dispatch) => {
  shop.UpdateStatusAllEmployess(data, (e) => {
    dispatch(fetchStatusemployeeschilds(e, data));
  });
};


export const CreateFetchSalary = (data) => (dispatch) => {
  shop.CreateFetchSalary(data, (e) => {
    dispatch(createsalarychilds(e, data));
  });
};


export const CreateAmountDeduction = (data) => (dispatch) => {
  shop.CreateDeductionSlry(data, (e) => {
    dispatch(crcAmountdeduction(e, data));
  });
};

export const UpdateAmountDeduction = (data) => (dispatch) => {
  shop.EditDeductionSlry(data, (e) => {
    dispatch(updateAmountdeduction(e, data));
  });
};


export const DeleteAmountDeduction = (data) => (dispatch) => {
  shop.DeleteDeductionSlry(data, (e) => {
    dispatch(deleteAmountdeduction(e, data));
  });
};

export const HanldeFetchAllEmp = (data) => (dispatch) => {
  shop.HanldeFetchAllEmp(data, (e) => {
    dispatch(deleteexpenseempallchild(e, data));
  });
};
export const EditLeaveTYPEStore = (data) => (dispatch) => {
  shop.EditLeaveTYPEStore(data, (e) => {
    dispatch(leavtypechildeidt(e, data));
  });
};

export const HandleFetchStatusLeaveType = (data) => (dispatch) => {
  shop.HandleFetchStatusLeaveType(data, (e) => {
    dispatch(fetchchildleavtypeupdatestatsius(e, data));
  });
};

export const EditHndleStoreDisnts = (data) => (dispatch) => {
  shop.EditHndleStoreDisnts(data, (e) => {
    dispatch(childDispatcheditdesgn(e, data));
  });
};

export const FetchEditBranchesHnde = (data) => (dispatch) => {
  shop.FetchEditBranchesHnde(data, (e) => {
    dispatch(editchildbrandediting(e, data));
  });
};

export const FetchActiveDeactiveDSNTSStore = (data) => (dispatch) => {
  shop.FetchActiveDeactiveDSNTSStore(data, (e) => {
    dispatch(activechilddesignationsdeactive(e, data));
  });
};

export const FetchActiveDeactiveBrnStore = (data) => (dispatch) => {
  shop.FetchActiveDeactiveBrnStore(data, (e) => {
    dispatch(activechilddeactivebranche(e, data));
  });
};

export const ViewFetchDesignatns = (data) => (dispatch) => {
  shop.ViewFetchDesignatns(data, (e) => {
    dispatch(hanldegetchilddesignation(e, data));
  });
};

// export const GetLeaveMang = (data) => (dispatch) => {
//   shop.LeaveMangGet(data, (e) => {
//     dispatch(getleavesmanag(e, data));
//   });
// };

export const ViewFetchVendors = (data) => (dispatch) => {
  shop.ViewFetchVendorss(data, (e) => {
    dispatch(getchildhanldevendors(e, data));
  });
};

export const CreateAllVendors = (data) => (dispatch) => {
  shop.CreateVendorss(data, (e) => {
    dispatch(childhanldevendors(e, data));
  });
};



export const ViewFetchBrnchs = (data) => (dispatch) => {
  shop.ViewFetchBrnchs(data, (e) => {
    dispatch(getchildhanldebranch(e, data));
  });
};

export const ViewFetchLveTpye = (data) => (dispatch) => {
  shop.ViewFetchLveTpye(data, (e) => {
    dispatch(leavetypehandlegetchild(e, data));
  });
};

export const CreateFetchBranch = (data) => (dispatch) => {
  shop.CreateFetchBranch(data, (e) => {
    dispatch(childhanldebranch(e, data));
  });
};

export const CreateLeaveTypeeFetch = (data) => (dispatch) => {
  shop.CreateLeaveTypeeFetch(data, (e) => {
    dispatch(leavetypehandlechild(e, data));
  });
};

export const DesinationsFetchCreatee = (data) => (dispatch) => {
  shop.DesinationsFetchCreatee(data, (e) => {
    dispatch(hanldechilddesignation(e, data));
  });
};

export const IncreptionPassDataStore = (data) => (dispatch) => {
  shop.increptionDataStore(data, (e) => {
    dispatch(increptionPass(e, data));
  });
};

export const pdfUnlock = (data) => (dispatch) => {
  shop.Unlockpdff(data, (e) => {
    dispatch(unlockeddpff(e, data));
  });
};

export const FetchGetAllReports = (data) => (dispatch) => {
  shop.FetchGetAllReports(data, (e) => {
    dispatch(typeCheckreports(e, data));
  });
};


export const FetchProjectProfitandLoss = (data) => (dispatch) => {
  shop.FetchGetProjAllPandL(data, (e) => {
    dispatch(getProjProfitandLossreports(e, data));
  });
};


export const FetchCustomerEarningReports = (data) => (dispatch) => {
  shop.FetchCustomerearnings(data, (e) => {
    dispatch(getCustomerEarningreports(e, data));
  });
};


export const FetchExpenseSummaryReports = (data) => (dispatch) => {
  shop.FetchExpenseReport(data, (e) => {
    dispatch(getExpenseReportsSummary(e, data));
  });
};



export const FetchOvrProfitAnsLossReports = (data) => (dispatch) => {
  shop.FetchOverallPandLReport(data, (e) => {
    dispatch(getOverallReportsPL(e, data));
  });
};


export const FetchCompanyProfitandLoss = (data) => (dispatch) => {
  shop.FetchCompanyPandLReport(data, (e) => {
    dispatch(getCompanyReportsPL(e, data));
  });
};

export const fetchCompanyDataa = (data) => (dispatch) => {
  shop.fetchCompanyDataa(data, (e) => {
    dispatch(fetchComapny(e, data));
  });
};

export const CustomerDataa = (data) => (dispatch) => {
  shop.CustomerrrDataa(data, (e) => {
    dispatch(CustomerData(e, data));
  });
};

export const CustomerSpecificUserEdit = (data) => (dispatch) => {
  shop.CustomerrrEditDataa(data, (e) => {
    dispatch(CustomerDataEdit(e, data));
  });
};

export const CustomerUserDelete = (data) => (dispatch) => {
  shop.CustomerrrDeleteDataa(data, (e) => {
    dispatch(CustomerDataDelete(e, data));
  });
};

export const FetchRecuringData = (data) => (dispatch) => {
  shop.FetchRecuringData(data, (e) => {
    dispatch(recuringChild(e, data));
  });
};

export const CreateRecuringFetch = (data) => (dispatch) => {
  shop.CreateRecuringFetch(data, (e) => {
    dispatch(createRedChild(e, data));
  });
};

export const fetchCurrency = (data) => (dispatch) => {
  shop.fetchCurrency(data, (e) => {
    dispatch(fetchCurrencyData(e, data));
  });
};

export const fetchDashboard = (data) => (dispatch) => {
  shop.fetchDashboard(data, (e) => {
    dispatch(fetchDasboardData(e, data));
  });
};
export const PaymentVoucherDetail = (data) => (dispatch) => {
  shop.PaymentVoucherDetail(data, (e) => {
    dispatch(PaymentVoucherDetailData(e, data));
  });
};

export const fetchTaxesComp = (data) => (dispatch) => {
  shop.fetchTaxesComp(data, (e) => {
    dispatch(fetchTaxess(e, data));
  });
};

export const fetchNumberField = (data) => (dispatch) => {
  shop.fetchNumberField(data, (e) => {
    dispatch(fetchNumberfield(e, data));
  });
};

export const fetchCustomer = (data) => (dispatch) => {
  shop.fetchCustomer(data, (e) => {
    dispatch(fetchCustomerr(e, data));
  });
};

export const fetchQuoatationGetAll = (data) => (dispatch) => {
  shop.fetchQuoatationGetAll(data, (e) => {
    dispatch(fetchquatIons(e, data));
  });
};

export const fetchUsersGetAll = (data) => (dispatch) => {
  shop.fetchUsersGetAll(data, (e) => {
    dispatch(fetchUsersAll(e, data));
  });
};

export const detailsUsersGetAll = (data) => (dispatch) => {
  shop.detailsUsersGetAll(data, (e) => {
    dispatch(detailsUsersAll(e, data));
  });
};

export const detailsUsersDelete = (data) => (dispatch) => {
  shop.detailsUsersDeleteAll(data, (e) => {
    dispatch(deleteUsersAll(e, data));
  });
};

export const CreateUsersAll = (data) => (dispatch) => {
  shop.CreateAllUsers(data, (e) => {
    dispatch(createUsersAll(e, data));
  });
};

export const fetchAllInvoice = (data) => (dispatch) => {
  shop.fetchAllInvoice(data, (e) => {
    dispatch(fetchsmallInvoice(e, data));
  });
};

export const fetchAllChallan = (data) => (dispatch) => {
  shop.fetchAllChallan(data, (e) => {
    dispatch(fetchsmallChallan(e, data));
  });
};

export const fetchChartOfAccount = (data) => async (dispatch) => {
  try {
    const result = await new Promise((resolve, reject) => {
      shop.fetchChartOfAccount(data, (e) => {
        resolve(e);
      });
    });

    dispatch(fetchAccountt(result, data));
  } catch (error) { }
};

export const fetchLoginUser = (data) => (dispatch) => {
  shop.fetchLoginUser(data, (e) => {
    dispatch(fssetchsogsinUser(e, data));
  });
};

export const fetchStoreQuotation = (data) => (dispatch) => {
  shop.fetchStoreQuotation(data, (e) => {
    dispatch(storequAoser(e, data));
  });
};

export const updateStoreQuotation = (data) => (dispatch) => {
  shop.updateStoreQuotation(data, (e) => {
    dispatch(updateQuotation(e, data));
  });
};
export const fetchViewQuotation = (data) => (dispatch) => {
  shop.fetchViewQuotation(data, (e) => {
    dispatch(viewQuotation(e, data));
  });
};

export const fetchInvoiceRegisterr = (data) => (dispatch) => {
  shop.fetchInvoiceRegisterr(data, (e) => {
    dispatch(invoiceSmall(e, data));
  });
};

export const fetchMainInvoices = (data) => (dispatch) => {
  shop.fetchMainInvoices(data, (e) => {
    dispatch(mainInvoice(e, data));
  });
};
export const updateMainInvoice = (data) => (dispatch) => {
  shop.updateMainInvoices(data, (e) => {
    dispatch(mainInvoiceUpdate(e, data));
  });
};

export const fetchChallan = (data) => (dispatch) => {
  shop.fetchChallan(data, (e) => {
    dispatch(fetchchildchallan(e, data));
  });
};

export const fetchUpdateCompany = (data) => (dispatch) => {
  shop.fetchUpdateCompany(data, (e) => {
    dispatch(NewfetchUpdatedata(e, data));
  });
};

export const DeleteChartAccount = (data) => (dispatch) => {
  shop.DeleteChartAccount(data, (e) => {
    dispatch(fetchremoveAccountof(e, data));
  });
};

export const CreateFetchRolee = (data) => (dispatch) => {
  shop.CreateFetchRolee(data, (e) => {
    dispatch(specificroless(e, data));
  });
};

export const fetchEditCompany = (data) => (dispatch) => {
  shop.fetchUpdateCompany(data, (e) => {
    dispatch(NewfetcheditCustomerr(e, data));
  });
};

export const fetchInvoieWditt = (data) => (dispatch) => {
  shop.fetchInvoieWditt(data, (e) => {
    dispatch(fetchinvoiceeidt(e, data));
  });
};

export const EduitChallabn = (data) => (dispatch) => {
  shop.EduitChallabn(data, (e) => {
    dispatch(fetcheditchallan(e, data));
  });
};

export const fetchUpdateTaxes = (data) => (dispatch) => {
  shop.fetchUpdateTaxes(data, (e) => {
    dispatch(fetchTaxupdate(e, data));
  });
};

export const fetchEditAccounts = (data) => (dispatch) => {
  shop.fetchEditAccounts(data, (e) => {
    dispatch(fetchupdateAccountChart(e, data));
  });
};

export const fetchDeleteCompany = (data) => (dispatch) => {
  shop.fetchDeleteCompany(data, (e) => {
    dispatch(NewfetchDeletedata(e, data));
  });
};

export const Delete = (data) => (dispatch) => {
  shop.Delete(data, (e) => {
    dispatch(fetchremoveAccountof(e, data));
  });
};

export const fetchDeleteCustomer = (data) => (dispatch) => {
  shop.fetchDeleteCustomer(data, (e) => {
    dispatch(NewfetchDeletecustomerr(e, data));
  });
};

export const fetchDeleteTax = (data) => (dispatch) => {
  shop.fetchDeleteTax(data, (e) => {
    dispatch(fetchTaxdelete(e, data));
  });
};

export const fetchRegisterCompany = (data) => (dispatch) => {
  shop.fetchRegisterCompany(data, (e) => {
    dispatch(fetchregistercompany(e, data));
  });
};

export const fetchRegisterTaxxx = (data) => (dispatch) => {
  shop.fetchRegisterTaxxx(data, (e) => {
    dispatch(fetchregistertax(e, data));
  });
};

export const fetchRegistereacccount = (data) => (dispatch) => {
  shop.fetchRegistereacccount(data, (e) => {
    dispatch(accountregisterr(e, data));
  });
};

export const fetchRegisterCustomerrr = (data) => (dispatch) => {
  shop.fetchRegisterCustomerrr(data, (e) => {
    dispatch(fetchregistercustomere(e, data));
  });
};

export const detchViewChallanDetails = (data) => (dispatch) => {
  shop.detchViewChallanDetails(data, (e) => {
    dispatch(fetchallanDetail(e, data));
  });
};

export const fetchNEwDEtailsQuoata = (data) => (dispatch) => {
  shop.fetchNEwDEtailsQuoata(data, (e) => {
    dispatch(fetchuotationDetail(e, data));
  });
};

export const fetchHandleTemplateDetails = (data) => (dispatch) => {
  shop.fetchHandleTemplateDetails(data, (e) => {
    dispatch(smallfetchtemdetai(e, data));
  });
};

export const fetchInvoiceDetails = (data) => (dispatch) => {
  shop.fetchInvoiceDetails(data, (e) => {
    dispatch(detchvocieDetail(e, data));
  });
};

export const fetchPaymentVocuherr = (data) => (dispatch) => {
  shop.fetchPaymentVocuherr(data, (e) => {
    dispatch(paymentsmallvouch(e, data));
  });
};
export const fetchExpenseVocuherr = (data) => (dispatch) => {
  shop.fetchExpenseVocuherr(data, (e) => {
    dispatch(expensesmallvouch(e, data));
  });
};
export const updatePaymentVocuherr = (data) => (dispatch) => {
  shop.updatePaymentVocuherr(data, (e) => {
    dispatch(update_paymentsmallvouch(e, data));
  });
};

export const tchpaymentVoucherVieww = (data) => (dispatch) => {
  shop.tchpaymentVoucherVieww(data, (e) => {
    dispatch(viewsmallPaymentsses(e, data));
  });
};

export const fetchTemplateGett = (data) => (dispatch) => {
  shop.fetchTemplateGett(data, (e) => {
    dispatch(fetchtemplatesmallss(e, data));
  });
};

export const fetchEditttTemplatee = (data) => (dispatch) => {
  shop.fetchEditttTemplatee(data, (e) => {
    dispatch(fetchEdittempplate(e, data));
  });
};

export const EditREcuringMail = (data) => (dispatch) => {
  shop.EditREcuringMail(data, (e) => {
    dispatch(updatechildmail(e, data));
  });
};

export const fetchDeleteeyTemplatess = (data) => (dispatch) => {
  shop.fetchDeleteeyTemplatess(data, (e) => {
    dispatch(fetchdeletetempattyye(e, data));
  });
};

export const fetchAllVwwTrmsoitins = (data) => (dispatch) => {
  shop.fetchAllVwwTrmsoitins(data, (e) => {
    dispatch(termsconditionssalllll(e, data));
  });
};

export const fetchAllTrmActive = (data) => (dispatch) => {
  shop.fetchAllTrmActive(data, (e) => {
    dispatch(termsconditionssactivee(e, data));
  });
};

export const fetchtemrsCotitonsEdit = (data) => (dispatch) => {
  shop.fetchtemrsCotitonsEdit(data, (e) => {
    dispatch(childtermconiupdatte(e, data));
  });
};

export const fetchTermcoftioDetailsseses = (data) => (dispatch) => {
  shop.fetchTermcoftioDetailsseses(data, (e) => {
    dispatch(temrscidntiondetail(e, data));
  });
};

export const fetchTrmsCotntionDeletee = (data) => (dispatch) => {
  shop.fetchTrmsCotntionDeletee(data, (e) => {
    dispatch(condtiotermdlete(e, data));
  });
};

export const FetchAllRoleView = (data) => (dispatch) => {
  // const history=useHistory()
  shop.FetchAllRoleView(data, (e) => {
    dispatch(contantAllviewroless(e, data));
  });
};

export const EditRole = (data) => (dispatch) => {
  shop.EditRole(data, (e) => {
    dispatch(specificroless(e, data));
  });
};

export const fetchTermsCondtiosnsn = (data) => (dispatch) => {
  shop.fetchTermsCondtiosnsn(data, (e) => {
    dispatch(fetchchildsenregistr(e, data));
  });
};

export const ChangePassword = (data) => (dispatch) => {
  shop.ChangePassword(data, (e) => {
    dispatch(PostChangePassword(e, data));
  });
};

export const AssignPermissionsShop = (data) => (dispatch) => {
  // const history=useHistory()
  shop.AssignPermissionsShop(data, (e) => {
    dispatch(Childassigpermissions(e, data));
  });
};

export const getAllPermissions = (data) => (dispatch) => {
  // const history=useHistory()
  shop.getAllPermissions(data, (e) => {
    dispatch(childALLPermisssions(e, data));
  });
};

export const fetchTemplateSend = (data) => (dispatch) => {
  shop.fetchTemplateSend(data, (e) => {
    dispatch(fetchchildtempregi(e, data));
  });
};

export const GetRolePermissions = (data) => (dispatch) => {
  shop.GetRolePermissions(data, (e) => {
    dispatch(rolePermissions(e, data));
  });
};

// ______________________________DASHBOARD_____________________________________________________________

export const GetinvoiceReports = (data) => (dispatch) => {
  shop.GetAllinvoiceReports(data, (e) => {
    dispatch(reportsAllinvoice(e, data));
  });
};

export const GetquotationsReports = (data) => (dispatch) => {
  shop.GetAllquotationsReports(data, (e) => {
    dispatch(reportsAllquotations(e, data));
  });
};

export const GetchallansReports = (data) => (dispatch) => {
  shop.GetAllchallansReports(data, (e) => {
    dispatch(reportsAllchallans(e, data));
  });
};
export const GetTopRatedCustomersData = (data) => (dispatch) => {
  shop.GetTopRatedCustomers(data, (e) => {
    dispatch(reportsGetTopRatedCustomers(e, data));
  });
};

// ______________________________DASHBOARD____________________________________________________________

// _________________________________REPORTS________________________________________________________________

export const GetledgerReports = (data) => (dispatch) => {
  shop.GetAllledgerReports(data, (e) => {
    dispatch(reportsAllledger(e, data));
  });
};

export const ExportAllInvoices = (data) => (dispatch) => {
  shop.AllInvoiceExp(data, (e) => {
    dispatch(exportinvoiceAll(e, data));
  });
};

export const ExportAllVoucher = (data) => (dispatch) => {
  shop.AllVoucherExp(data, (e) => {
    dispatch(exportvoucherAll(e, data));
  });
};

export const ExportAllQuotations = (data) => (dispatch) => {
  shop.AllQuotationsExp(data, (e) => {
    dispatch(exportquotationAll(e, data));
  });
};

export const ExportAllChallans = (data) => (dispatch) => {
  shop.AllChallanExp(data, (e) => {
    dispatch(exportchallanAll(e, data));
  });
};

export const QuotDelete = (data) => (dispatch) => {
  shop.AllQuotationDel(data, (e) => {
    dispatch(Deletequotation(e, data));
  });
};

export const InvoiceDelete = (data) => (dispatch) => {
  shop.AllInvoiceDel(data, (e) => {
    dispatch(Deleteinvoice(e, data));
  });
};

export const challanDelete = (data) => (dispatch) => {
  shop.AllChallanDel(data, (e) => {
    dispatch(Deletechallan(e, data));
  });
};

export const voucherDelete = (data) => (dispatch) => {
  shop.AllVoucherDel(data, (e) => {
    dispatch(Deletevoucher(e, data));
  });
};

export const leaveManagement = (data) => (dispatch) => {
  shop.AllLeaveMang(data, (e) => {
    dispatch(leavemang(e, data));
  });
};

export const leaveManagementCr = (data) => (dispatch) => {
  shop.CrLeaveMang(data, (e) => {
    dispatch(leavemangCr(e, data));
  });
};


export const GenerateSalaryCr = (data) => (dispatch) => {
  shop.CrGenerateSlry(data, (e) => {
    dispatch(generateslryCr(e, data));
  });
};

export const GenerateSalaryUpdate = (data) => (dispatch) => {
  shop.EditGenerateSlry(data, (e) => {
    dispatch(generateslryUpdate(e, data));
  });
};

export const leaveManagementGet = (data) => (dispatch) => {
  shop.AllLeavesMangGet(data, (e) => {
    dispatch(Getleavemang(e, data));
  });
};

export const leaveManagementCreatte = (data) => (dispatch) => {
  shop.CreateLeaveMang(data, (e) => {
    dispatch(mangCreate(e, data));
  });
};

export const leaveManagementUpdate = (data) => (dispatch) => {
  shop.UpdateLeaveMang(data, (e) => {
    dispatch(leavemangUpdte(e, data));
  });
};

export const leaveManagementDelete = (data) => (dispatch) => {
  shop.DeleteLeaveMang(data, (e) => {
    dispatch(leavemangDel(e, data));
  });
};

export const AllEmployees = (data) => (dispatch) => {
  shop.AllEmployees(data, (e) => {
    dispatch(AllEmployeeMang(e, data));
  });
};


export const AllEmployeesFilter = (data) => (dispatch) => {
  shop.FilterAllEmployees(data, (e) => {
    dispatch(AllEmployeeMangfilters(e, data));
  });
};

export const FetchEmployeeInExcel = (data) => (dispatch) => {
  shop.FetchAllEmployeeXcelIn(data, (e) => {
    dispatch(childEmployeeExcel(e, data));
  });
};

export const AllLoanType = (data) => (dispatch) => {
  shop.GetLoanTypes(data, (e) => {
    dispatch(LoanTypeget(e, data));
  });
};

export const RosterCreate = (data) => (dispatch) => {
  shop.CrcRoster(data, (e) => {
    dispatch(AllRostercrc(e, data));
  });
};

export const EmployeeInfoCr = (data) => (dispatch) => {
  shop.EmployeesCr(data, (e) => {
    dispatch(CrcEmployeeMang(e, data));
  });
};

export const EmployeeInfoUpdate = (data) => (dispatch) => {
  shop.EmployeesUpdate(data, (e) => {
    dispatch(EditEmployeeMang(e, data));
  });
};

export const PolicyGett = (data) => (dispatch) => {
  shop.GetCompanypolicy(data, (e) => {
    dispatch(getPolicy(e, data));
  });
};

export const PolicyCreate = (data) => (dispatch) => {
  shop.CrcCompanypolicy(data, (e) => {
    dispatch(createPolicy(e, data));
  });
};

export const ApproveLeavesReq = (data) => (dispatch) => {
  shop.ReqLeaveapprove(data, (e) => {
    dispatch(LeaveRequestApproved(e, data));
  });
};

export const CancelLeavesReq = (data) => (dispatch) => {
  shop.ReqLeavecancel(data, (e) => {
    dispatch(CancelLeaveRequest(e, data));
  });
};

export const RejectLeavesReq = (data) => (dispatch) => {
  shop.ReqLeavereject(data, (e) => {
    dispatch(RejectLeaveRequest(e, data));
  });
};

export const GetAllLoans = (data) => (dispatch) => {
  shop.GetAllLoans(data, (e) => {
    dispatch(getLoans(e, data));
  });
};

export const GetAllPayslip = (data) => (dispatch) => {
  shop.GetAllPayslip(data, (e) => {
    dispatch(getPayslip(e, data));
  });
};

export const ExportGetAllPayslip = (data) => (dispatch) => {
  shop.GetAllPayslipexport(data, (e) => {
    dispatch(getPayslipExport(e, data));
  });
};


export const GetAllPaySalary = (data) => (dispatch) => {
  shop.GetAllPayslry(data, (e) => {
    dispatch(getSalaryPay(e, data));
  });
};


export const GetAllPaySalaryFetch = (data) => (dispatch) => {
  shop.GetAllfetchPayslry(data, (e) => {
    dispatch(fetchfiltergetSalaryPay(e, data));
  });
};



export const GetAttendance = (data) => (dispatch) => {
  shop.GetAllAttendance(data, (e) => {
    dispatch(getAttendance(e, data));
  });
};

export const GetAttendanceexports = (data) => (dispatch) => {
  shop.ExportGetAllAttendance(data, (e) => {
    dispatch(getAttendanceexport(e, data));
  });
};


export const CreateAttendance = (data) => (dispatch) => {
  shop.CreateAllAttendance(data, (e) => {
    dispatch(crcAttendance(e, data));
  });
};

export const AttendanceExtract = (data) => (dispatch) => {
  shop.ExtractAllAttendance(data, (e) => {
    dispatch(extractAttendance(e, data));
  });
};

export const GetPayroll = (data) => (dispatch) => {
  shop.GetAllPayroll(data, (e) => {
    dispatch(getPayroll(e, data));
  });
};


export const GetDeduction = (data) => (dispatch) => {
  shop.GetAmountDeduction(data, (e) => {
    dispatch(getDeduction(e, data));
  });
};

export const GetAllowancePayroll = (data) => (dispatch) => {
  shop.GetAllowanceAllPayroll(data, (e) => {
    dispatch(getAllowanceAmount(e, data));
  });
};
export const GetAllCurrency = (data) => (dispatch) => {
  shop.GetCurrencyAll(data, (e) => {
    dispatch(getCurrencyy(e, data));
  });
};

export const CreateAllCurrency = (data) => (dispatch) => {
  shop.CrcCurrencyAll(data, (e) => {
    dispatch(createCurrencyy(e, data));
  });
};

export const UpdateAllCurrency = (data) => (dispatch) => {
  shop.EditCurrencyAll(data, (e) => {
    dispatch(editCurrencyy(e, data));
  });
};

export const DeleteAllCurrency = (data) => (dispatch) => {
  shop.DeleteCurrencyAll(data, (e) => {
    dispatch(deleteCurrencyy(e, data));
  });
};



export const CreateAllowancePayroll = (data) => (dispatch) => {
  shop.CrcAllowanceAllPayroll(data, (e) => {
    dispatch(createAllowanceAmount(e, data));
  });
};

export const UpdateAllowancePayroll = (data) => (dispatch) => {
  shop.EditAllowanceAllPayroll(data, (e) => {
    dispatch(updateAllowanceAmount(e, data));
  });
};


export const DeleteAllowancePayroll = (data) => (dispatch) => {
  shop.AllowanceDelete(data, (e) => {
    dispatch(deleteAllowanceAmount(e, data));
  });
};

export const GetEmpAllowance = (data) => (dispatch) => {
  shop.GetAllEmpAllowance(data, (e) => {
    dispatch(getAllowanEmp(e, data));
  });
};

export const UpdateEmpAllowance = (data) => (dispatch) => {
  shop.UpdtaeAllEmpAllowance(data, (e) => {
    dispatch(editAllowanEmp(e, data));
  });
};

export const UpdateSatDeduct = (data) => (dispatch) => {
  shop.UpdateSaturationDeduct(data, (e) => {
    dispatch(editSatDeduction(e, data));
  });
};

export const UpdateOtherPayment = (data) => (dispatch) => {
  shop.UpdateAllOtherPayments(data, (e) => {
    dispatch(editotherpayMents(e, data));
  });
};

export const GetAllowance = (data) => (dispatch) => {
  shop.GetPayrollAllowance(data, (e) => {
    dispatch(getAllowancePayroll(e, data));
  });
};

export const CrcEmployeeSalary = (data) => (dispatch) => {
  shop.CreateEmpSalary(data, (e) => {
    dispatch(createEmpsalary(e, data));
  });
};

export const GetEmployeeSalary = (data) => (dispatch) => {
  shop.GetEmpslry(data, (e) => {
    dispatch(getEmpsalary(e, data));
  });
};

export const CrcAllLoans = (data) => (dispatch) => {
  shop.CrcAllLoans(data, (e) => {
    dispatch(createLoans(e, data));
  });
};

export const ReturnLoans = (data) => (dispatch) => {
  shop.CrcReturnLoans(data, (e) => {
    dispatch(returnLoans(e, data));
  });
};

export const UpdateAllLoans = (data) => (dispatch) => {
  shop.EditAllLoans(data, (e) => {
    dispatch(updateLoans(e, data));
  });
};

// ____________________________________REPORTS_________________________________________________________________
