import MainCard from "components/MainCard";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useRef, useState } from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin, Table, Space, Input, Modal } from "antd";
import { dispatch } from "store/index";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";

import {
  fetchCustomer,
  fetchCompanyDataa,
  fetchChallan,
  fetchAllInvoice,
  fetchAllChallan,
  fetchAllVwwTrmsoitins,
  fetchAllTrmActive,
} from "store/action/index";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TaxOption from "components/taxOption";
import { Select as SelectAnt } from "antd";
import SearchTextSelect from "components/selects/SearchTextSelect";
import { toast } from "react-toastify";
import { NewPostData, PostRegisterData } from "store/shop/shop";
import * as url from "../../store/constant/Endpoints";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import draftToHtml from "draftjs-to-html";

// ==============================|| Create / Edit Company - SICPA ||============================== //

const CreateChallan = ({
  quotationsssactive,
  customerData,
  companyData,
  invoiceget,
  challanget,
  termsGetin,
}) => {
  const location = useLocation();
  let userId = localStorage.getItem("Id");

  const userFlagRef = useRef();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [deliveryfilter, setDeliveryFilter] = useState([]);
  // const htmlContent = "<p>s<ins>sdgsgsgsg</ins>dssdgsdg<strong>sdgsdgds</strong></p>";

  const [TermDecccc, SetTermCondiDescri] = useState();
  const [totalPriceSum, setTotalPriceSum] = useState(0);
  const [description, setDescription] = useState("");
  const [s_no, setSNo] = useState(0);
  const [qty, setQty] = useState("");
  const [unit, setUnitPrice] = useState("");
  const [total_price, setTotalPrice] = useState("");
  const [inv_ref, set_Inv_Ref] = useState();
  const [parent_id, set_parent_id] = useState(0);
  const [is_lock, setIsLock] = useState("1");

  const [options, setOptions] = useState([{ tax: "" }]);

  const [challan_id, setChallanOIId] = useState("");

  const [type, setttype] = useState("");

  const [company_id, setCompanyId] = useState(
    location.state !== null ? location.state?.company_id : ""
  );

  const [customer_id, setCustomerId] = useState(
    location.state !== null ? location.state?.customer_id : ""
  );

  const [loading, setLoading] = useState(false);

  const [currency, setcurrency] = useState(
    location.state !== null ? location.state?.currency : ""
  );

  const [nextSNo, setNextSNo] = useState(1);
  const [isInvoiceDisabled, setIsInvoiceDisabled] = useState(true);
  const [customeragainsdatainv, setInvoiceData] = useState([]);

  const [company, setCompany] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [Modalcompany, setModalCompany] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [AdditionalPrice, setAdditionalPrice] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );

  const [discount, setdiscount] = useState(
    location.state !== null ? location.state?.payment_terms : ""
  );
  const [date, setdate] = useState(
    location.state !== null
      ? location.state?.date
      : new Date().toISOString().split("T")[0]
  );
  const [discount_type, setdiscountType] = useState(
    location.state !== null ? location.state?.quotation_type : ""
  );

  const [company_name, setCompanyName] = useState(
    location.state !== null ? location.state?.company_name : ""
  );
  const [termconditonGet, setTermCOndit] = useState();

  const [invoiceTerms, setinvoiceTerms] = useState("");

  const options3 =
    companyData && companyData.length > 0
      ? companyData.map((v) => ({ value: v.id, label: v.company_name }))
      : [];

  const handleChange = (value) => {
    setCompanyId(value); // Assuming you have a state variable like 'customer_id'
  };

  useEffect(() => {
  }, [location.state]);
  useEffect(() => {
    setLoading(true);
    // Dispatch the action when the component mounts
    dispatch(fetchAllTrmActive({ setLoading: setLoading }));
  }, []);

  const options2 =
    customerData && customerData.length > 0
      ? customerData.map((v) => ({ value: v.id, label: v.company_name }))
      : [];
  const handleChangee = (value) => {
    setCustomerId(value);
    let token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `${url.base_url}${url.getallinvoicescustomerid
        }?customer_id=${value}&company_id=${localStorage.getItem(
          "companyName"
        )}`,
        config
      )
      .then((res) => {
        // for (let index = 0; index < array.length; index++) {
        //   const element = array[index];

        // }
        setIsInvoiceDisabled(res.data.data.length >= 1 ? false : true);

        setInvoiceData(res.data.data);
        // cb(res.data);
        // payload?.setLoading(false);
      })
      .catch((err) => {
        setIsInvoiceDisabled(true);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  };

  const CutomOptionsss =
    customeragainsdatainv && customeragainsdatainv?.length > 0
      ? [
        { value: null, label: "None" }, // Add a "None" option
        ...customeragainsdatainv?.map((v) => ({
          value: v.id,
          label: v.invoice_number,
        })),
      ]
      : [];

  const NewCutom =
    challanget && challanget?.data?.challan?.length > 0
      ? [
        { value: null, label: "None" }, // Add a "None" option
        ...challanget?.data?.challan?.map((v) => ({
          value: v.id,
          label: v.del_number,
        })),
      ]
      : [];

  const [fields, setFields] = useState([
    { sno: nextSNo, description: "", qty: "", unit: "", total_price: "" },
  ]);
  const [pdfType, setPdfType] = useState("");

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    // Calculate total price
    const qty = parseFloat(updatedFields[index].qty);
    const unitPrice = parseFloat(updatedFields[index].unitPrice);
    updatedFields[index].total_price = (qty * unitPrice).toFixed(2);

    setFields(updatedFields);
  };

  const [fieldData, setFieldData] = useState({
    description: [],
    s_no: [],
    qty: [],
    unit: [],
    total_price: [],
    company_id: [company_id],
    customer_id,
    discount_type,
    discount,
    date,
    currency,
    tax_id: [],
    is_parent: [1],
  });

  const [taxadd, setTaxAdd] = useState([{ tax_id: "" }]);

  const addField = () => {
    setNextSNo(nextSNo + 1);
    setFields([
      ...fields,
      {
        sno: nextSNo,
        description: "",
        qty: "",
        unitPrice: "",
        total_price: "",
      },
    ]);
  };

  const addTaxxField = () => {
    setTaxAdd([...taxadd, { tax_id: "" }]);
  };
  const createdIddd = localStorage.getItem("newIdd");

  useEffect(() => {
    dispatch(fetchAllVwwTrmsoitins());

    if (!Array.isArray(companyData?.data)) {
      dispatch(fetchCompanyDataa({ setLoading: setLoading }));
    }
  }, []);

  const TermCoditoptions =
    quotationsssactive && quotationsssactive?.data?.length > 0
      ? quotationsssactive?.data?.map((v) => ({
        value: v.term_description,
        label: v.term_name,
      }))
      : [];

  // Initialize separate arrays for description, sno, qty, and unitPrice
  const updateFieldData = () => {
    const updatedFieldData = {
      description: [],
      s_no: [],
      qty: [],
      unit: [],
      total_price: [],
      company_id: [company_id],
      customer_id,
      discount,
      discount_type,
      date,
      currency,
      tax_id: [],
      is_parent: [1],
    };

    fields.forEach((field) => {
      updatedFieldData.s_no.push(field.sno);
      updatedFieldData.description.push(field.description);
      updatedFieldData.qty.push(field.qty);
      updatedFieldData.unit.push(field.unit);
      updatedFieldData.total_price.push(field.total_price);
    });

    // Assuming taxadd is an array of tax IDs you want to include
    // updatedFieldData.tax_id = taxadd.map((taxField) => taxField.tax_id);

    // Update fieldData with the new data
    setFieldData(updatedFieldData);
  };

  // taxadd.forEach((field) => {
  //   fieldData.tax_id.push(field.tax_id);
  // });

  useEffect(() => {
    updateFieldData();
  }, [fields]);

  useEffect(() => {
    const calculateTotalPriceSum = () => {
      if (fieldData.total_price && fieldData.total_price.length > 0) {
        const sum = fieldData.total_price.reduce(
          (acc, val) => acc + parseFloat(val),
          0
        );
        setTotalPriceSum(sum.toFixed(2));
      } else {
        setTotalPriceSum(0);
      }
    };

    calculateTotalPriceSum();
  }, [fieldData.total_price]);

  const handleCompanyChange = (key, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, company: value } : item
    );
    setData(updatedData);

    // Update Modalcompany with the latest value for the specific row
    const newRow = updatedData.find((item) => item.key === key);
    if (newRow) {
      setModalCompany(newRow.company);
    }
  };

  // Function to handle changes in the Additional Price field
  const handleAdditionalPriceChange = (key, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, additionalPrice: value } : item
    );
    setData(updatedData);

    // Update AdditionalPrice with the latest value for the specific row
    const newRow = updatedData.find((item) => item.key === key);
    if (newRow) {
      setAdditionalPrice(newRow.additionalPrice);
    }
  };

  const [newRow, setNewRow] = useState("");

  const handleModalAddRow = () => {
    const newKey = (data.length + 1).toString();
    setData([...data, { key: newKey, description: newRow }]);
    setNewRow("");
  };

  const handleModalDeleteRow = (key) => {
    const updatedData = data.filter((item) => item.key !== key);
    setData(updatedData);
  };

  const initialData = [
    {
      key: 0,
      description: "",
      quantity: "",
      unit: "",
      total_price: "",
    },
  ];

  const [data, setData] = useState(initialData);
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(
    data.length > 1
  );
  const formatNumber = (value) => {
    if (!value && value !== 0) return "";
    return new Intl.NumberFormat('en-US').format(value);
  };
  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      width: 100,
      key: "sno",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      render: (text, record, index) => (
        <Input
          value={record.description}
          onChange={(e) =>
            handleFieldChange(index, "description", e.target.value)
          }
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      render: (text, record, index) => (
        <Input
          value={formatNumber(record.qty || 0)}
          type="text"
          onChange={(e) => {
            if (record.unit === "Pcs") {
              handleFieldChange(
                index,
                "qty",
                Math.max(
                  0,
                  parseInt(Math.max(0, parseFloat(e.target.value)), 10)
                )
              );
            } else {
              handleFieldChange(
                index,
                "qty",
                Math.max(0, parseFloat(e.target.value))
              );
            }
          }}
        />
      ),
    },

    // {
    //   title: "Unit*",
    //   dataIndex: "unit",
    //   key: "unit",
    //   render: (text, record, index) => (
    //     <FormControl variant="outlined" fullWidth>
    //       <InputLabel htmlFor={`unit-dropdown-${index}`}></InputLabel>
    //       <Select
    //         label="Unit"
    //         value={record?.unit ?? ""}
    //         onChange={(e) => handleFieldChange(index, 'unit', e.target.value)}
    //         input={<OutlinedInput label="Unit" id={`unit-dropdown-${index}`} />}
    //         style={{ height: "32px" }}
    //       >
    //         <MenuItem disabled value="">
    //           <em>Unit</em>
    //         </MenuItem>
    //         <MenuItem value="Pcs">Pcs</MenuItem>
    //         <MenuItem value="Meter">Meter</MenuItem>
    //         <MenuItem value="Ft">FT</MenuItem>
    //       </Select>
    //     </FormControl>
    //   ),
    // },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          <Button
            style={{ color: "red" }}
            onClick={() => handleDeleteRow(index)}
            disabled={!isDeleteButtonEnabled} // Disable the button if isDeleteButtonEnabled is false
          >
            <DeleteOutlined size={25} color={"#fff"} />
          </Button>
        </Space>
      ),
    },
  ];

  const handleAddRowModal = () => {
    const newRow = {
      key: data.length,
      company: Modalcompany, // Initialize with Modalcompany
      additionalPrice: AdditionalPrice, // Initialize with AdditionalPrice
    };
    setData([...data, newRow]);
    setIsDeleteButtonEnabled(true); // Enable the delete button
  };
  const handleAddRow = () => {
    const newRow = {
      key: fields.length,
      description: "",
      quantity: "",
      unit: "",
      total_price: "",
    };
    setFields([...fields, newRow]);
    setIsDeleteButtonEnabled(true); // Enable the delete button
  };

  const handleDeleteRow = (index) => {
    // const updatedData = data.filter((item) => item.key !== key);
    if (fields.length > 1) {
      const updatedFields = [...fields];
      updatedFields.splice(index, 1); // Remove the row at the given index
      setFields(updatedFields); // Recalculate sno for all rows
      if (updatedFields.length === 1) {
        setIsDeleteButtonEnabled(false); // Disable the delete button
      }
    }
  };
  const handleDescriptionChange = (index, value) => {
    const updatedFieldData = { ...fieldData };
    updatedFieldData.description[index] = value;
    setFieldData(updatedFieldData);
  };

  const handleQuantityChange = (record, value) => {
    const updatedData = data.map((item) =>
      item.key === record.key ? { ...item, quantity: value } : item
    );
    setData(updatedData);
  };

  const handleUnitPriceChange = (record, value) => {
    const updatedData = data.map((item) =>
      item.key === record.key ? { ...item, unit: value } : item
    );
    setData(updatedData);
  };

  //   useEffect(() => {
  //     if (!Array.isArray(challanget.data)) {
  //       //   store.dispatch(fetchAllChallan());
  //     } else if (!Array.isArray(invoiceget.data)) {
  //       //   store.dispatch(fetchAllInvoice());
  //     } else if (invoiceget.data && challanget.data && quotationget.data) {
  //       setAllinvioces(invoiceget.data.invoice);
  //       setAllChallan(challanget.data.challan);
  //       setAllQuoatauionss(quotationget.data.quitation);
  //     }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //     setTemplateDatatAll(templateget?.data);

  //     let finalll = emplateAllData?.filter((item) => item.templete_type === "1");
  //     setInvoiceFilter(finalll);
  //     let quofieeefilerr = emplateAllData?.filter((item) => item.templete_type === "2");
  //     setQuoatationFilter(quofieeefilerr);
  //     let deliveryyfilerr = emplateAllData?.filter((item) => item.templete_type === "3");
  //     setDeliveryFilter(deliveryyfilerr);
  //     let Filterieing = termsGetin?.data?.filter((item) => item.term_type == 1);
  //     setTermConditions(Filterieing);
  //     let newwFiltwrr = termsGetin?.data?.filter((item) => item.term_type == 2);

  //     setQuoatationTerm(newwFiltwrr);
  //   }, [challanget, quotationget]);

  // useEffect(() => {

  //   dispatch(fetchNumberField({ id: company_id }));
  //   setNumber_Field(numberfield?.data)
  // }, [company_id]);

  useEffect(() => {
    dispatch(fetchCustomer());
    dispatch(fetchCompanyDataa());
  }, []);

  const handleSubmit = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const htmlString = html.toString();

    const payload = {
      description: fields.map((field) => field.description).filter(Boolean),
      s_no: fields.map((field, i) => i + 1),
      qty: fields.map((field) => field.qty).filter(Boolean),
      unit: fields.map((field) => field.unit),
      it_id: fields.map((field) => field.id).filter(Boolean),
      company_id: localStorage.getItem("companyName"),
      customer_id: customer_id,
      challan_id: type,
      terms_conditions: TermDecccc,
      created_by: createdIddd,
      date: date,
      is_create: 1,
      inv_ref: inv_ref,
      parent_id: parent_id,
      is_lock: is_lock,
      // pdf_type: pdfType,
      // Include other fields needed in your payload
    };
    const { description, s_no, qty, unit } = payload;
    if (description.length && s_no.length && qty.length && unit.length) {
      if (location.state === null) {
        dispatch(
          fetchChallan({
            payload,
            setLoading: setLoading,
          })
        );
        setLoading(true);
      } else {
        // update dispatch here
      }
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("companyName") && customer_id) {
      let getComp = localStorage.getItem("companyName");
      // let accountID = challanget?.data?.challan?.filter((item) => item. == company_id);
      // dispatch(fetchAllChallan({setLoading:setLoading,company_id:company_id}))
      dispatch(fetchAllInvoice({ getComp, customer_id, allData: "all" }));
    }
  }, [company_id, customer_id]);

  useEffect(() => {
    if (localStorage.getItem("companyName") && customer_id) {
      let getComp = localStorage.getItem("companyName");

      // let accountID = challanget?.data?.challan?.filter((item) => item. == company_id);
      // dispatch(fetchAllChallan({setLoading:setLoading,company_id:company_id}))
      dispatch(
        fetchAllChallan({
          setLoading: setLoading,
          getComp,
          customer_id,
          parent_id: parent_id,
        })
      );
    }
  }, [company_id, customer_id]);

  const get_selected_invoice_tabel = (value) => {
    const formData = new FormData();
    formData.append("id", value);
    formData.append("created_by", createdIddd);

    if (createdIddd) {
      try {
        PostRegisterData(url.base_url + url.invoicedetails, formData).then(
          (res) => {
            if (
              res?.data?.data &&
              res?.data?.data?.invoice_details?.in_items &&
              res?.data?.data?.invoice_details?.in_items.length
            ) {
              const desData = res?.data?.data?.invoice_details?.in_items
                ?.map((val) => {
                  if (Number(val.qty) - Number(val?.dl_qty ?? 0) > 0) {
                    return {
                      ...val,
                      unit: val.unit_type,
                      qty:
                        Number(val.qty) > 0
                          ? Number(val.qty) - Number(val?.dl_qty ?? 0)
                          : Number(val.qty),
                    };
                  }
                })
                .filter(Boolean);
              setFields(desData);

              desData.length > 1 && setIsDeleteButtonEnabled(true);
            }
          }
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const InoiceNumHandle = (value) => {
    get_selected_invoice_tabel(value);
    set_Inv_Ref(value);
  };

  const DeliveryReferenceHanlde = (value) => {
    set_parent_id(value);
  };

  useEffect(() => {
    if (termconditonGet) {
      SetTermCondiDescri(termconditonGet);
    }
  }, [termconditonGet]);

  useEffect(() => {
    // Log the raw content state for testing purposes
    const rawContentState = convertToRaw(editorState.getCurrentContent());
  }, [editorState]);

  // const TermCoditoptions =
  //   termsGetin && termsGetin?.data?.length > 0
  //     ? termsGetin?.data?.map((v) => ({
  //       value: v.term_description,
  //       label: v.term_name,
  //     }))
  //     : [];

  const HandleTermCondiotonHadle = (value) => {
    setTermCOndit(value);
  };
  return (
    <MainCard>
      <Spin spinning={loading ? true : companyData ? false : true}>
        {/* templateget?.data && termsGetin?.data ? true : false */}
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <div className="row">
              {/* <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Company*
                  </InputLabel>
              
                </Stack>
                <SelectAnt
                  showSearch
                  style={{
                    width: '100%',
                    height: '54px',
                    marginTop: '8px',

                  }}
                  value={company_id}
                  onChange={handleChange}
                  handleChangeValue={handleChange}
                  tokenSeparators={[',']}
                  options={options3}
                  placeholder="Select Company"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />


              </div> */}

              <div className="col-md-6 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Company*
                  </InputLabel>
                </Stack>
                <SearchTextSelect
                  showSearch
                  style={{
                    width: "100%",
                    height: "54px",
                    marginTop: "8px",
                  }}
                  onChange={handleChangee}
                  handleChangeValue={handleChangee}
                  value={customer_id}
                  tokenSeparators={[","]}
                  options={options2}
                  placeholder="Select Customer"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </div>

              {
                <div className="col-md-6 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Invoice Number*
                    </InputLabel>
                  </Stack>
                  <SelectAnt
                    disabled={isInvoiceDisabled}
                    showSearch
                    style={{
                      width: "100%",
                      height: "54px",
                      marginTop: "5px",
                    }}
                    tokenSeparators={[","]}
                    onChange={InoiceNumHandle}
                    options={CutomOptionsss}
                    placeholder="Invoice Number"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </div>
              }

              <div className="col-md-4 my-2">
                <ThemeInput
                  type="date"
                  name="Date*"
                  value={date}
                  onChange={(e) => setdate(e.target.value)}
                />
              </div>

              {/* <div className="col-md-6 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="company">
                      Select Download PDF Type*
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        onChange={(e) => setPdfType(e.target.value)}
                        id="company"
                        name="Select_Download_PDF_Type"
                        displayEmpty
                        input={<OutlinedInput />}
                      >
                        <MenuItem disabled value="">
                          <em>Select Download PDF Type</em>
                        </MenuItem>
                        <MenuItem value="pdf_with_watermark">
                          PDF with watermark
                        </MenuItem>
                        <MenuItem value="pdf_without_watermark">
                          PDF without watermark
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </div> */}

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    File Lock
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      style={{ height: "52px" }}
                      value={is_lock}
                      onChange={(e) => {
                        setIsLock(e.target.value);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select</em>
                      </MenuItem>
                      {/* You can add your select options here */}
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="5">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </div>
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Term Conditons Select*
                  </InputLabel>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <SearchTextSelect
                    handleChangeValue={HandleTermCondiotonHadle}
                    options={TermCoditoptions}
                    value={termconditonGet}
                  />
                </Stack>
              </div>
              <div className="col-md-12 my-2">
                <label className="mr-3">"Terms Conditions"</label>
                <div style={{ height: "300px", overflowY: "scroll" }}>
                  {/* <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={setEditorState}
                  /> */}
                  <ReactQuill
                    theme="snow"
                    value={TermDecccc}
                    onChange={SetTermCondiDescri}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {fields && fields.length ? (
                <Table
                  className="rounded"
                  pagination={false}
                  columns={columns}
                  dataSource={fields}
                  scroll={{
                    x: 1300,
                  }}
                />
              ) : (
                <>
                  <center className="mt-4" style={{ color: "green" }}>
                    <b>All items have already been delivered.</b>
                  </center>
                </>
              )}
            </div>

            <div className="row my-5">
              {fields && fields.length ? (
                <div className=" col-md-3 my-2">
                  <Button
                    onClick={handleSubmit}
                    disableElevation
                    className="px-3"
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {location.state === null
                      ? "Save Delivery Note"
                      : "Update Delivery Note"}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
      </Spin>
    </MainCard>
  );
};

const mapStateToProps = (state) => {
  return {
    companyData: state?.company?.companyData?.data,
    customerData: state?.customerdata?.customerData?.data,
    customerDe: state?.customerdata?.customerData?.data,
    invoiceget: state?.getinvoiceall?.invoiceget?.data,
    challanget: state?.getchallan?.challanget,
    termsGetin: state.termsAlling.termsGetin,
    quotationsssactive: state?.quotationssActiveDetail?.quotationsssactive,
  };
};

export default connect(mapStateToProps)(CreateChallan);
