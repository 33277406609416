// material-ui

import MainCard from "components/MainCard";
import "assets/css/buttonHover.css";
import { onPageChange } from "helper/paginationHelper";
import { SearchQueryFormatter } from "helper/searchHelper";

import { Button } from "@mui/material";
import {
  editButton,
  deactivateButton,
  renderButton,
} from "components/actionButtons/actionsButtons";

import { Refresh2 } from "iconsax-react";
import { Table, Pagination, Spin } from "antd";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { fetchTemplateGett, fetchDeleteeyTemplatess } from "store/action/index";
import { AES, enc } from "crypto-js";
import * as url from "../../store/constant/Endpoints";
import { dispatch } from "store/index";
import { useNavigate } from "react-router-dom";
import { TemplateColumn } from "components/columns/columns";
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const AllTemplates = ({ templateget }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [params, setparams] = useState({ page: 1 });
  const [View_Challan, setNewChallan] = useState();

  // ----------------------------------------Permissions-----------------------------------------------
  let permissionUser = localStorage.getItem("logindata");
  let decryptedData = null;
  if (permissionUser) {
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  }
  const permissions = JSON.parse(decryptedData);
  const allowedPermissions =
    permissions && permissions.length !== 0
      ? permissions.map((permission) => permission?.permission)
      : [];
  const permissionsAccessFunction = (brand) => {
    return (
      allowedPermissions &&
      allowedPermissions.length !== 0 &&
      allowedPermissions.some((permission) => permission.includes(brand))
    );
  };

  // ----------------------------------------Permissions-----------------------------------------------

  useEffect(() => {
    dispatch(
      fetchTemplateGett({
        params: SearchQueryFormatter(params),
        setLoading: setLoading,
      })
    );
  }, [params]);

  const handleEdit = (path, params) => {
    navigate("/domain-parameter/create-template", { state: params });
  };

  const handleUpdateStatus = (userId) => {
    dispatch(fetchDeleteeyTemplatess({ id: userId, setLoading: setLoading }));

    dispatch(fetchTemplateGett({ setLoading: setLoading }));
  };

  const columns = TemplateColumn({ params, setparams });
  columns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {allowedPermissions.includes("domainParameter.templates.edit")
            ? editButton(
                "create-template",
                "/domain-parameter/create-template",
                record,
                handleEdit
              )
            : null}
          {allowedPermissions.includes("domainParameter.templates.delete")
            ? deactivateButton("deactivate-tax", record, handleUpdateStatus)
            : null}
        </span>
      );
    },
  });

  return (
    <MainCard>
      <div style={{ textAlign: "end" }}>
        <Button
          sx={{ display: "initial" }}
          onClick={() => {
            setLoading(true);
            dispatch(fetchTemplateGett({ setLoading: setLoading }));
          }}
          size="medium"
          type="submit"
          variant="contained"
          color="primary"
          className="mx-3 refershButton active refreshButton"
        >
          <Refresh2
            className="refreshIcon"
            size="32"
            color="#ffffff"
            variant="TwoTone"
          />
        </Button>

        {allowedPermissions.includes("domainParameter.templates.create")
          ? renderButton(
              "create-template",
              "/domain-parameter/create-template",
              "Add Templates"
            )
          : null}
      </div>

      <Spin spinning={loading}>
        <Table
          className="border rounded"
          style={{ marginTop: 25, overflowX: "auto" }}
          dataSource={templateget?.data?.data ?? []}
          columns={columns}
          pagination={false}
          rowKey="id"
          components={{
            body: {
              cell: ({ style, ...restProps }) => {
                return (
                  <td style={{ ...style, padding: "15px" }} {...restProps} />
                );
              },
            },
          }}
        />
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 25 }}
        >
          {/* <Pagination
            total={Number(lastPage ? lastPage : 1) * 10}
            defaultCurrent={params.page}
            onChange={(page) => onPageChange(setparams, params, page)}
          /> */}
        </div>
      </Spin>
    </MainCard>
  );
};
const mapStateToProps = (state) => {
  return {
    templateget: state?.templateGet?.templateget,
  };
};

export default connect(mapStateToProps)(AllTemplates);
