// // import React from "react";
// // import { Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";
// // import FormHelperText from "@mui/material/FormHelperText";
// // const ThemeInput = (props) => {
// //   let { name, type, placeholder, id, errorText, error, min, max } = props;

// //   return (
// //     <Grid item xs={12}>
// //       <Stack spacing={1}>
// //         <InputLabel htmlFor={id}>{name}</InputLabel>
// //         <OutlinedInput
// //           id={id}
// //           type={type}
// //           name={name}
// //           max={max}
// //           min={min}
// //           placeholder={placeholder}
// //           helperText="There has been an error"
// //           fullWidth
// //           err
// //           {...props}
// //         />
// //         {error ? (
// //           <FormHelperText
// //             style={{ margin: "0px 3%", color: "red", fontSize: 10 }}
// //           >
// //             {errorText}
// //           </FormHelperText>
// //         ) : (
// //           <div></div> // Render nothing when error is false
// //         )}
// //       </Stack>
// //     </Grid>
// //   );
// // };

// // ThemeInput.defaultProps = {
// //   errorText: "",
// //   error: false,
// // };
// // export default ThemeInput;

// import React from "react";
// import { Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";
// import FormHelperText from "@mui/material/FormHelperText";

// // Utility function to format numbers with commas
// const formatNumberWithCommas = (value) => {
//   if (!value) return "";

//   // Remove any existing commas and non-numeric characters (except decimal point)
//   const cleanValue = value.toString().replace(/,/g, "");

//   // Split number into integer and decimal parts
//   const parts = cleanValue.split(".");

//   // Format integer part with commas
//   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

//   // Rejoin with decimal part if it exists
//   return parts.join(".");
// };

// const ThemeInput = (props) => {
//   let { 
//     name, 
//     type, 
//     placeholder, 
//     id, 
//     errorText, 
//     error, 
//     min, 
//     max, 
//     value,
//     onChange,
//     ...otherProps 
//   } = props;

//   // Handle value display formatting
//   const displayValue = type == "number" ? formatNumberWithCommas(value) : value;

//   // Handle onChange to maintain the actual numeric value while showing formatted display
//   const handleChange = (e) => {
//     if (type == "number") {
//       // Remove commas for the actual value
//       const numericValue = e.target.value.replace(/,/g, "");

//       // Create a new event with the cleaned value
//       const newEvent = {
//         ...e,
//         target: {
//           ...e.target,
//           value: numericValue
//         }
//       };

//       if (onChange) {
//         onChange(newEvent);
//       }
//     } else if (onChange) {
//       onChange(e);
//     }
//   };

//   return (
//     <Grid item xs={12}>
//       <Stack spacing={1}>
//         <InputLabel htmlFor={id}>{name}</InputLabel>
//         <OutlinedInput
//           id={id}
//           type={type == "number" ? "text" : type} // Change type to text for number inputs
//           name={name}
//           max={max}
//           min={min}
//           placeholder={placeholder}
//           value={displayValue}
//           onChange={handleChange}
//           fullWidth
//           {...otherProps}
//         />
//         {error && (
//           <FormHelperText
//             style={{ margin: "0px 3%", color: "red", fontSize: 10 }}
//           >
//             {errorText}
//           </FormHelperText>
//         )}
//       </Stack>
//     </Grid>
//   );
// };

// ThemeInput.defaultProps = {
//   errorText: "",
//   error: false,
//   type: "text"
// };

// export default ThemeInput;

// import React from "react";
// import { Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";
// import FormHelperText from "@mui/material/FormHelperText";

// const formatNumberWithCommas = (value) => {
//   if (!value) return "";
//   const cleanValue = value.toString().replace(/,/g, "");
//   const parts = cleanValue.split(".");
//   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   return parts.join(".");
// };

// const ThemeInput = (props) => {
//   let {
//     name,
//     type,
//     placeholder,
//     id,
//     errorText,
//     error,
//     min,
//     max,
//     value,
//     onChange,
//     ...otherProps
//   } = props;

//   const displayValue = type == "number" ? formatNumberWithCommas(value) : value;

//   const handleChange = (e) => {
//     if (type == "number") {
//       const inputValue = e.target.value;
//       const numericValue = inputValue.replace(/[^\d.]/g, '');
//       const parts = numericValue.split('.');
//       const sanitizedValue = parts[0] + (parts.length > 1 ? '.' + parts[1] : '');
//       const newEvent = {
//         ...e,
//         target: {
//           ...e.target,
//           value: sanitizedValue
//         }
//       };
//       if (onChange) {
//         onChange(newEvent);
//       }
//     } else if (onChange) {
//       onChange(e);
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (type == "number") {
//       const isNumber = /[\d.]/.test(e.key);
//       const isControlKey = e.key === "Backspace" || e.key === "Delete" || e.key === "ArrowLeft" || e.key === "ArrowRight";
//       const hasDecimal = e.target.value.includes('.');
//       const isDecimal = e.key === '.';
//       if (!isNumber && !isControlKey) {
//         e.preventDefault();
//       }
//       if (isDecimal && hasDecimal) {
//         e.preventDefault();
//       }
//     }
//   };

//   return (
//     <Grid item xs={12}>
//       <Stack spacing={1}>
//         <InputLabel htmlFor={id}>{name}</InputLabel>
//         <OutlinedInput
//           id={id}
//           type="text"
//           name={name}
//           max={max}
//           min={min}
//           placeholder={placeholder}
//           value={displayValue}
//           onChange={handleChange}
//           onKeyPress={handleKeyPress}
//           fullWidth
//           {...otherProps}
//         />
//         {error && (
//           <FormHelperText
//             style={{ margin: "0px 3%", color: "red", fontSize: 10 }}
//           >
//             {errorText}
//           </FormHelperText>
//         )}
//       </Stack>
//     </Grid>
//   );
// };

// ThemeInput.defaultProps = {
//   errorText: "",
//   error: false,
//   type: "text"
// };

// export default ThemeInput;






import React from "react";
import { Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

const formatNumberWithCommas = (value) => {
  if (!value) return "";
  const cleanValue = value.toString().replace(/,/g, "");
  const parts = cleanValue.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

const ThemeInput = (props) => {
  let {
    name,
    type,
    placeholder,
    disabled,
    id,
    errorText,
    error,
    min,
    max,
    value,
    onChange,
    ...otherProps
  } = props;

  const displayValue = type === "number" ? formatNumberWithCommas(value) : value;

  const handleChange = (e) => {
    if (type === "number") {
      const inputValue = e.target.value;
      const numericValue = inputValue.replace(/[^\d.]/g, "");
      const parts = numericValue.split(".");
      const sanitizedValue = parts[0] + (parts.length > 1 ? "." + parts[1] : "");
      const newEvent = {
        ...e,
        target: {
          ...e.target,
          value: sanitizedValue,
        },
      };
      if (onChange) {
        onChange(newEvent);
      }
    } else if (onChange) {
      onChange(e);
    }
  };

  const handleKeyPress = (e) => {
    if (type === "number") {
      const isNumber = /[\d.]/.test(e.key);
      const isControlKey =
        e.key === "Backspace" ||
        e.key === "Delete" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight";
      const hasDecimal = e.target.value.includes(".");
      const isDecimal = e.key === ".";
      if (!isNumber && !isControlKey) {
        e.preventDefault();
      }
      if (isDecimal && hasDecimal) {
        e.preventDefault();
      }
    }
  };

  return (
    <Grid item xs={12}>
      <Stack spacing={1}>
        <InputLabel htmlFor={id}>{name}</InputLabel>
        <OutlinedInput
          id={id}
          type={type === "date" ? "date" : "text"} // 👈 Handle date type
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          fullWidth
          {...(type === "date"
            ? {
              value: value,  // 👈 Date type ke liye value allow kar rahe hain
              onChange: onChange,  // 👈 Date ke liye onChange pass kiya
            }
            : {
              value: displayValue,
              onChange: handleChange,
              onKeyPress: handleKeyPress,
              max,
              min,
              ...otherProps,
            })}
        />
        {error && (
          <FormHelperText style={{ margin: "0px 3%", color: "red", fontSize: 10 }}>
            {errorText}
          </FormHelperText>
        )}
      </Stack>
    </Grid>
  );
};

ThemeInput.defaultProps = {
  errorText: "",
  error: false,
  type: "text",
};

export default ThemeInput;
