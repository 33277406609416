import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, Container, Toolbar } from "@mui/material";

// project-imports
import Drawer from "./Drawer";
import Header from "./Header";
import Footer from "./Footer";
import Breadcrumbs from "components/@extended/Breadcrumbs";

import navigation from "menu-items";
import { dispatch, useSelector } from "store";
import { openDrawer } from "store/reducers/menu";
import { DRAWER_WIDTH } from "config";
import Modals from "../../pages/companymodal";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));
  const { drawerOpen } = useSelector((state) => state.menu);
  const [cmpglbmodope, setCmpGlbe] = useState(false);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    let checkCondition = localStorage.getItem("companyName");
    let assignUserChecking = localStorage.getItem("userassigncompany");
    let HasModalBeenShown = localStorage.getItem("hasModalBeenShown");
    if (assignUserChecking == "false") {
      if (!checkCondition) {
        setCmpGlbe(true);
        localStorage.setItem("hasModalBeenShown", "true");
      }
      console.log("user assign company");
      if (!HasModalBeenShown) {
        // If not, show the modal and set the flag in localStorage
        setCmpGlbe(true);
        localStorage.setItem("hasModalBeenShown", "true");
      }
    } else {
      return console.log("user noooo assign company");
    }
  }, []);

  const handleClose = () => {
    setCmpGlbe(false);
  };

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />

        <Box
          component="main"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
          }}
        >
          <Toolbar sx={{ mt: "inherit" }} />
          <Container
            sx={{
              position: "relative",
              minHeight: "calc(100vh - 110px)",
              display: "flex",
              flexDirection: "column",
              maxWidth: "2000px !important",
            }}
          >
            <Breadcrumbs navigation={navigation} title />
            <Outlet />
            <Footer />
          </Container>
        </Box>
      </Box>
      <Modals open={cmpglbmodope} handleClose={handleClose} />
    </>
  );
};

export default MainLayout;
