import { TOP_RATED_CUSTOMERS } from "../../constant/ActionType";

export default function dashboardallTopRatedCustomer(state = {
    topRatedCustomerGet: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case TOP_RATED_CUSTOMERS:
            return {
                ...state,
                topRatedCustomerGet: action.data,
                statuscode: action.status
            };

        default:
    }
    return state;
}
